import {
  addDaysToDate,
  calculateDifferenceInDays,
  formatUnit,
  getDateFromString,
} from "../CommonUtils/CommonUtils";
import {
  DropdownOptionGroupType,
  DropdownOptionType,
  MONTHS,
} from "../../types/CommonTypes";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";

interface CreateOptionsProps {
  data: string[];
  allLabel?: string;
  omitAll?: boolean;
  omitSorting?: boolean;
}

/**
 * Function to create options from data.
 * @param {string} [allLabel] the label to use for "All"
 * @param {string[]} [data] the data to transform into options
 * @return {DropdownOptionType[]} the options
 */
export const createOptions = ({
  data,
  allLabel,
  omitAll,
  omitSorting,
}: CreateOptionsProps): DropdownOptionType[] => [
  ...(!omitAll
    ? [
        {
          label: allLabel ?? "All",
          value: "All",
        },
      ]
    : []),
  ...(!omitSorting ? data.sort() : data).map((option: string) => ({
    label: option,
    value: option,
  })),
];

export type GroupOptionData = Record<string, string[] | DropdownOptionType[]>;

interface CreateGroupOptionsProps {
  data: GroupOptionData;
  omitPrefixes?: boolean;
}

/**
 * Function to create group options from data.
 * @param {string[]} [data] the data to transform into options
 * @return {DropdownOptionGroupType[]} the options
 */
export const createGroupOptions = ({
  data,
  omitPrefixes,
}: CreateGroupOptionsProps): DropdownOptionGroupType[] =>
  Object.entries(data)
    .sort((a, b) => (a[0] < b[0] ? -1 : 1))
    .map(([key, values]) => ({
      label: key,
      options: values
        .sort((a, b) =>
          typeof a === "string" && typeof b === "string"
            ? a.localeCompare(b)
            : (a as DropdownOptionType).label.localeCompare(
                (b as DropdownOptionType).label
              )
        )
        .map((opt) =>
          typeof opt === "string"
            ? {
                label: opt,
                value: omitPrefixes ? opt : `${key}${"-"}${opt}`,
              }
            : opt
        ),
    }));

export const defaultOptions: DropdownOptionType[] = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "A",
    value: "a",
  },
  {
    label: "B",
    value: "b",
  },
  {
    label: "C",
    value: "c",
  },
];

export interface CreateDropdownLimitProps {
  unit: string;
  max: number;
  required?: boolean;
}

export interface CreateDropdownLimitReturnType {
  validate: (value: string[]) => boolean;
  errorMessage: string;
}

/**
 * Function to create the properties for a multi-select dropdown with a limit.
 * @param {string} [unit] the unit for the dropdown
 * @param {number} [max] the max number of values to choose on the dropdown
 * @param {required} [boolean] whether the dropdown is required.
 * @returns
 */
export const createDropdownLimit = ({
  unit,
  max,
  required,
}: CreateDropdownLimitProps): CreateDropdownLimitReturnType => {
  return {
    validate: (values: string[]) =>
      required
        ? values.length > 0 && values.length <= max
        : values.length <= max,
    errorMessage: `${
      required ? `At least one ${unit} selected. ` : ""
    }No more than ${max} ${formatUnit(unit, max)}.`,
  };
};

export const getMonthOptions = (year: string): DropdownOptionType[] =>
  MONTHS.filter(
    (_, index) => getDateFromString(`${year}-${index + 1}-01`) < new Date()
  ).map((month, index) => ({
    label: month,
    value: `${index + 1 < 10 ? "0" : ""}${index + 1}`, // MM format
  }));

// TODO: make dependent on today's date.
export const getYearOptions = (): DropdownOptionType[] => {
  const today = new Date();
  const currentYear: number = today.getUTCFullYear();
  return createOptions({
    data: Array.from(Array(currentYear - 2021 + 1) as string[], (_, index) =>
      (currentYear - index).toString()
    ),
    omitAll: true,
  });
};

/**
 * Function to check if a dropdown option is a DropdownOptionGroupType
 * @param {DropdownOptionType | DropdownOptionGroupType} [option] the option to check
 * @returns {boolean} whether an option is a DropdownOptionGroupType
 */
export const isDropdownOptionGroupType = (
  option: DropdownOptionType | DropdownOptionGroupType
): option is DropdownOptionGroupType =>
  (option as DropdownOptionGroupType).options !== undefined;

const GROUP_OPTION_SEPARATOR = "-";

/**
 * Function to strip out the group prefixes.
 * @param {string[]} [groupValues] the group values to remove
 * @param {string[]} [values] the values to strip the prefixes
 * @returns {string[]} the new values
 */
export const removeGroupPrefixes = (
  groupValues: string[],
  values: string[]
): string[] =>
  values.map((value) =>
    value.replace(
      new RegExp(`^(${groupValues.join("|")})${GROUP_OPTION_SEPARATOR}`),
      ""
    )
  );

/**
 * Filter function for a multi dropdown when using search query.
 * @param {string | undefined} [searchQuery] the search query
 * @param {string[]} [values] the values
 * @param {RegExp} [searchRegExp] the regular expression to test
 * @returns {Function} the callback function
 */
export const multiDropdownSearchFilter =
  (searchQuery: string | undefined, values: string[], searchRegExp: RegExp) =>
  ({ value, label }: DropdownOptionType) =>
    !searchQuery || values.includes(value) || searchRegExp.test(label);

/**
 * Function to check if dropdown options are a DropdownOptionGroupType[]
 * @param {DropdownOptionType[] | DropdownOptionGroupType[]} [options] the array of options to check
 * @returns {boolean} whether an option is a DropdownOptionGroupType[]
 */
export const isDropdownOptionGroupArray = (
  options: DropdownOptionType[] | DropdownOptionGroupType[]
): options is DropdownOptionGroupType[] =>
  (options as DropdownOptionGroupType[]).length > 0 &&
  isDropdownOptionGroupType(options[0]);

/**
 * Function to get all the end year options based on start year.
 * @param {string} [year] the year
 * @returns {DropdownOptionType[]} the dropdown options
 */
export const getEndYearOptions = (
  startWeek: string,
  startYear: string
): DropdownOptionType[] => {
  const YEAR_OPTIONS = getYearOptions();
  const index = YEAR_OPTIONS.findIndex(({ value }) => value === startYear);

  // If a start year hasn't been chosen.
  if (index < 0) {
    return YEAR_OPTIONS;
  }

  // If the last possible week is chosen as the start week.
  if (startWeek === "52") {
    return YEAR_OPTIONS.slice(index + 1);
  }

  return YEAR_OPTIONS.slice(index);
};

/**
 * Function to render the week number with the date.
 * @param {Object} [props] the values to refer to from a date
 * @returns {JSX.Element} the rendered date
 */
export const renderDate = ({ label, date }): JSX.Element => {
  const dateObj = new Date(date);
  const weekNumber = getFiscalWeekNumber(date);

  return (
    <Column spacing="none">
      <Text>{label}</Text>
      <strong>
        {dateObj.getUTCDay() === 6 && <Text>Week {weekNumber}</Text>}
      </strong>
    </Column>
  );
};

/**
 * Function to get the start and end dates of the fiscal week.
 * @param {number} [week] the week number
 * @param {number} [year] the year
 * @returns {[string, string]} the start and end dates
 */
export const getFiscalWeekRange = (
  week: number,
  year: number
): [string, string] => {
  if (!isNaN(week) && !isNaN(year)) {
    const firstDayOfYear = getDateFromString(`${year}-01-01`);
    const firstSundayOfYear = addDaysToDate(
      firstDayOfYear,
      firstDayOfYear.getUTCDay() === 0 ? 0 : 7 - firstDayOfYear.getUTCDay()
    );
    const startDate = addDaysToDate(firstSundayOfYear, (week - 1) * 7);
    const endDate = addDaysToDate(startDate, 6);
    return [startDate, endDate];
  }
  return ["", ""];
};

/**
 * Function to get the fiscal week from a date
 * @param {Date | string} [date] the date
 * @returns {number} the week
 */
export const getFiscalWeekNumber = (date: string): number => {
  const year: number = getDateFromString(date).getFullYear();
  const firstDayOfYear = getDateFromString(`${year}-01-01`);
  const firstSundayOfYear = addDaysToDate(
    firstDayOfYear,
    firstDayOfYear.getDay() === 0 ? 0 : 7 - firstDayOfYear.getDay()
  );

  // Part of previous fiscal year.
  if (date < firstSundayOfYear) {
    return 52;
  } else if (date === firstSundayOfYear) {
    return 1;
  }
  return Math.ceil(calculateDifferenceInDays(firstSundayOfYear, date) / 7);
};

/**
 * Function to get all the fiscal week options.
 * @param {string} [year] the year
 * @returns {DropdownOptionType[]} the dropdown options
 */
export const getFiscalWeekOptions = (year: string): DropdownOptionType[] => {
  const mostRecentSaturday: string = getMostRecentSaturday();
  const mostRecentSaturdayDateObj: Date = getDateFromString(
    getMostRecentSaturday()
  );
  let weeks: number = 52;
  if (mostRecentSaturdayDateObj.getFullYear() === parseInt(year)) {
    weeks = Math.ceil(
      calculateDifferenceInDays(mostRecentSaturday, `${year}-01-01`) / 7
    );
  }
  return Array.from(Array(weeks).keys()).map((week) => {
    return {
      label: `${week + 1}`,
      value: `${week + 1}`,
    };
  });
};

/**
 * Function to get the most recent Saturday. This is useful since data
 * is loaded after a full week.
 * @returns {Date} the date (as a string)
 */
export const getMostRecentSaturday = (): string => {
  const today = new Date();
  return addDaysToDate(today, -(today.getUTCDay() + 1));
};

/**
 * Function to get all the start fiscal week options.
 * @param {string} [year] the year
 * @returns {DropdownOptionType[]} the dropdown options
 */
export const getStartWeekOptions = (
  startYear: string
): DropdownOptionType[] => {
  const options = getFiscalWeekOptions(startYear);
  const mostRecentSaturday = getDateFromString(getMostRecentSaturday());
  return mostRecentSaturday.getFullYear() === parseInt(startYear)
    ? options.slice(0, -1)
    : options;
};

/**
 * Function to get all the end fiscal week options.
 * @param {string} [year] the year
 * @returns {DropdownOptionType[]} the dropdown options
 */
export const getEndWeekOptions = (
  startWeek: string,
  startYear: string,
  endYear: string
): DropdownOptionType[] => {
  const options = getFiscalWeekOptions(endYear);
  return startYear === endYear ? options.slice(parseInt(startWeek)) : options;
};
/**
 * Function to get the ending day of the week with the given date.
 * @param {Date} [date] the date within the week
 * @returns {string} the starting date of the week (Monday) as string.
 */
export const getEndingDayOfWeek = (date: string | Date): string => {
  const day = (
    typeof date === "string" ? getDateFromString(date) : date
  ).getUTCDay(); // 6 is Saturday

  return addDaysToDate(date, 6 - day);
};

/**
 * Function to only allow Sundays to be selected.
 * @returns {Function} callback function that returns if a date should be disabled
 */
export const getDisabledDates = (date: string): boolean => {
  const dateObj = getDateFromString(date);
  const today = new Date();

  return dateObj.getUTCDay() !== 6 || dateObj > today;
};
