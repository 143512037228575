import { API } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api";
import { useCallback, useContext, useMemo, useState } from "react";
import { generatePath, useHistory, useLocation } from "react-router-dom";
import { Case, ClaimCaseAnalysis } from "../../API";
import {
  AnalysisType,
  ErrorType,
  MetricDurationType,
  MetricProps,
} from "../../types/CommonTypes";
import AppContext from "../../AppContext";

// Number of items per page in a table.
export const COUNT_PER_PAGE = 25;

/**
 * Hook to navigate to a route within the application.
 * @param {string} [route] the route to navigate to
 * @param {any} [params] the params to pass through
 * @param {string} [search] the search string to push
 * @returns {*}
 */
export const useRoute = () => {
  const history = useHistory();

  return (route: string, params?: any, search?: string) => () =>
    history.push({
      pathname: generatePath(route, params),
      search,
    });
};

/**
 * Hook to fetch query parameters from search object.
 * @returns {Function}
 */
export const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

/**
 * Function to change a given name to proper/title case.
 * @param {string} [name] the name
 * @returns {string}
 */
export const toProperCase = (name: string): string =>
  name.toLowerCase().replace(/\b[a-z]/g, (s) => s.toUpperCase());

/**
 * Function to get the date from a string.
 * @param {string} [date] the date in YYYY-MM-DD
 * @returns {Date} the date object
 */
export const getDateFromString = (date: string): Date => {
  if (!date) {
    return new Date(-1);
  }

  const dateParts = date.split("-").map((part) => parseInt(part)); // YYYY-MM-DD
  return new Date(Date.UTC(dateParts[0], dateParts[1] - 1, dateParts[2]));
};

/**
 * Function to format a time.
 * @param {string | undefined | null} [date] the time to format
 * @returns {string}
 */
export const formatTime = (
  time: string | undefined | null,
  timeZone?: string
): string =>
  time
    ? new Date(time).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        timeZone: timeZone ?? undefined,
        timeZoneName: "short",
      })
    : "Unknown";

interface DateOptions {
  long?: boolean;
  shortYear?: boolean;
  noYear?: boolean;
}

/**
 * Function to format a date.
 * @param {string | undefined | null} [date] the date to format
 * @param {Object} [options] object with options
 * @returns {string}
 */
export const formatDate = (
  date: string | undefined | null,
  options?: DateOptions
): string => {
  if (!date) {
    return "Unknown";
  }
  return date !== "N/A"
    ? getDateFromString(date).toLocaleDateString("en-US", {
        ...(options?.long
          ? {
              dateStyle: "long",
            }
          : {
              month: "2-digit",
              day: "2-digit",
              year: options?.shortYear
                ? "2-digit"
                : options?.noYear
                ? undefined
                : "numeric",
            }),
        timeZone: "UTC",
      })
    : "N/A";
};

/**
 * Function to format a unit string based on the value.
 * @param {string} [unit] the unit to format
 * @param {number} [value] the value
 * @returns {string}
 */
export const formatUnit = (unit: string, value: number) =>
  Math.abs(value) === 1 ? unit : unit + "s";

/**
 * Function to force inputs to go to blur upon clicking enter.
 * @param {any} [event] the "keyDown" event
 */
export const handleEnter = ({ key, target }) => {
  if (key === "Enter") target.blur(); // Blurring on enter.
};

/**
 * Function to handle blur events for text inputs.
 * @param {(string) => void} [setter] the setter function
 * @returns {(Event) => void} the function to call on blur.
 */
export const handleBlur =
  (setter: (string) => void) =>
  ({ target: { value } }) =>
    setter(value);

/**
 * Function to calculate the match confidence with four parameters.
 * @param {ClaimRosieAnalysisType} [analysis] the analysis object
 * @returns {number} the overall match confidence
 */
export const calculateMatchConfidence = (analysis: AnalysisType): number => {
  const values = Object.values(analysis);
  return values.reduce(
    (total, { confidence }) => (total += confidence / values.length),
    0
  );
};

/**
 * Function to calculate the difference in days between two dates.
 * @param {string} [startDate] the start date
 * @param {string} [endDate] the end date
 * @returns {number} the difference in days.
 */
export const calculateDifferenceInDays = (
  startDate: string,
  endDate: string
): number => {
  const startTime = new Date(startDate).getTime();
  const endTime = new Date(endDate).getTime();

  return Math.abs(Math.floor((endTime - startTime) / (24 * 3600 * 1000)));
};

/**
 * Function to return the date after some number of days.
 * @param {string} [date] the date
 * @param {number} [days] the number of days to add
 * @returns {string} the new date.
 */
export const addDaysToDate = (date: string | Date, days: number): string => {
  const startTime = (
    typeof date === "string" ? getDateFromString(date) : date
  ).getTime();
  const endTime = days * 24 * 3600 * 1000 + startTime;

  return formatISODate(new Date(endTime));
};

/**
 * Function to generate analysis for a given claim and case.
 * @param {Case} [caseItem] the case
 * @returns {AnalysisType} the analysis.
 */
export const generateAnalysis = (caseItem: Case): AnalysisType => {
  if (caseItem?.claimCaseAnalysis) {
    const { empNameScore, siteScore, lossDateDiffScore, bodyPartScore } =
      caseItem.claimCaseAnalysis as ClaimCaseAnalysis;

    return {
      employeeId: {
        confidence: parseInt(empNameScore ?? "0") / 100,
      },
      site: {
        confidence: parseInt(siteScore ?? "0") / 100,
      },
      lossDate: {
        confidence: parseInt(lossDateDiffScore ?? "0") / 100,
      },
      bodyPart: {
        confidence: parseInt(bodyPartScore ?? "0") / 100,
      },
    };
  } else {
    return {};
  }
};

/**
 * Function to make a comma-separated string from a string array.
 * @param {string[] | undefined} [array] the string array
 * @returns {string | undefined} either the string or undefined
 */
export const toArrayString = (
  array: string[] | undefined
): string | undefined =>
  array !== undefined && array.length ? `'${array.join("','")}'` : undefined;

/**
 * Async function to fetch the metrics based on the query and return type.
 * @param {string} [query] the query to call on GraphQL
 * @param {VisualizationProps} [props] the props to pass to the call
 * @returns {Promise<GraphQLResult<Type>>} the promise of the GraphQL result
 */
export async function fetchMetrics<Type>(
  query: string,
  variables: MetricProps
): Promise<GraphQLResult<Type>> {
  return await (API.graphql({
    query,
    variables,
  }) as Promise<GraphQLResult<Type>>);
}

/**
 * Function to strip the string of the ' character
 * @param {string} [metricProp] the metric property string
 * @returns {string[]} the new string
 */
export const stripQuotes = (metricProp: string): string =>
  metricProp.replace(/'/g, "");

/**
 * Function to replace with hyphen if string is empty.
 * @param {string} [value] the old string
 * @returns {string[]} the new string
 */
export const replaceIfEmpty = (value: string | null | undefined): string =>
  !!value ? value : "-";

export interface TrendDates {
  startDate: string;
  endDate: string;
  dateRange: string;
}

/**
 * Function to handle setting up dates for trend cards.
 * @param {MetricDurationType} [metricDuration] the metric duration
 * @param {string | undefined } [metricDateStart] the start date (if duration is day)
 * @param {string | null} [metricDate] usually the end date
 * @returns {CaseTrendDates} the date strings to use as filters for insights
 */
export const handleTrendDates = (
  metricDuration: MetricDurationType,
  metricDateStart: string | undefined,
  metricDate: string | null
): TrendDates => {
  let metricDateObject;
  if (
    metricDate &&
    !isNaN((metricDateObject = getDateFromString(metricDate)).getTime())
  ) {
    let startDate: string;
    let endDate: string;
    switch (metricDuration) {
      case "month":
        const year = metricDateObject.getUTCFullYear();
        const month = metricDateObject.getUTCMonth();
        const lastDayOfMonth = new Date(Date.UTC(year, month + 1, 0));
        const today = new Date();
        startDate = formatISODate(new Date(Date.UTC(year, month, 1)));
        endDate = formatISODate(
          lastDayOfMonth < today ? lastDayOfMonth : today
        );
        break;
      case "day":
        startDate = metricDateStart as string;
        endDate = metricDate;
        break;
      case "week":
      default:
        startDate = addDaysToDate(metricDate, -6);
        endDate = metricDate;
    }
    const dateRange: string = `date-range=${startDate},${endDate}`;

    return {
      startDate,
      endDate,
      dateRange,
    };
  }
  return {} as TrendDates;
};

export interface UseMetricsProps {
  query: string;
  queryName: string;
}

/**
 * Hook to set up variables for dashboard metrics.
 * @returns {[MetricType[] | undefined, boolean, Function, Function]} the metrics, the loading state, and the respective setters
 */
export function useMetrics<
  MetricsType, // type of metrics to fetch.
  MetricPropsType extends object, // passed in props for query.
  QueryType // type of query.
>({
  query,
  queryName,
}: UseMetricsProps): [MetricsType[] | undefined, boolean, Function, Function] {
  const { canSeeGenAIInsights } = useContext(AppContext);
  const [metrics, setMetrics] = useState<MetricsType[] | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  /**
   * Function to fetch the metrics for total claims.
   */
  const fetchMetrics = useCallback(
    async (metricProps: MetricPropsType, checkCancel: () => boolean) => {
      if (canSeeGenAIInsights !== undefined) {
        let newMetrics: MetricsType[] = [];
        setLoading(true);

        // Set up the reference to the promise.
        const promise = API.graphql({
          query,
          variables: metricProps,
        }) as Promise<GraphQLResult<QueryType>>;

        try {
          const { data } = await promise;
          if (data?.[queryName]) {
            newMetrics = data[queryName] as MetricsType[];
          }
        } catch (error) {
          const { errors } = error as ErrorType;
          if (errors) {
            // Handle errors.
          }
        } finally {
          // See if the component has mounted before setting.
          if (!checkCancel()) {
            setMetrics(newMetrics);
          }
          setLoading(false);
        }
      }
    },
    [query, queryName, canSeeGenAIInsights]
  );

  return [metrics, loading, setMetrics, fetchMetrics];
}

/**
 * Function to check if two dates have the same year.
 * @param {string | undefined | null} [date1] the date in YYYY-MM-DD
 * @param {string | undefined | null} [date2] the date in YYYY-MM-DD
 * @returns {Boolean} dates have the same year
 */
export const checkSameYear = (
  date1: string | undefined | null,
  date2: string | undefined | null
): Boolean => {
  if (!date1 || !date2) {
    return false;
  }
  return (
    getDateFromString(date1).getFullYear() ===
    getDateFromString(date2).getFullYear()
  );
};

/**
 * Function to generate a string of a date in YYYY-MM-DD format.
 * @param {Date} [date] the date object
 * @returns {string} the date in YYYY-MM-DD format
 */
export const formatISODate = (date: Date): string =>
  date.toISOString().substring(0, 10);

/**
 * Function to get the starting day of the week with the given date.
 * @param {Date} [date] the date within the week
 * @returns {string} the starting date of the week (Monday) as string.
 */
export const getStartingDayOfWeek = (date: string | Date): string => {
  const day = (
    typeof date === "string" ? getDateFromString(date) : date
  ).getUTCDay(); // 0 is Saturday

  return addDaysToDate(date, -1 * day);
};

/**
 * Function to generate a string using the metric props.
 * @param {MetricProps} [metricProps] the metric props
 * @returns {string} the string to describe the date range.
 */
export const formatMetricPropsDate = ({
  metricDuration,
  metricDate,
  metricDateStart,
}: MetricProps): string => {
  const dateObj = getDateFromString(metricDate);

  if (!isNaN(dateObj.getTime())) {
    switch (metricDuration) {
      case "day":
        return createDateRangeString(metricDateStart as string, metricDate);
      case "week":
        return createDateRangeString(
          getStartingDayOfWeek(metricDate),
          metricDate
        );
      case "month":
        const month = dateObj.getUTCMonth();
        const year = dateObj.getUTCFullYear();
        const lastDayOfMonth = new Date(Date.UTC(year, month + 1, 0));
        const today = new Date();
        return createDateRangeString(
          formatISODate(new Date(Date.UTC(year, month, 1))),
          formatISODate(lastDayOfMonth < today ? lastDayOfMonth : today)
        );
    }
  }
  return "";
};

/**
 * Function to format a value that's a number compactly.
 * @param {number} [value] the value
 * @returns {string} the formatted value in compact form
 */
export const formatCompactNumber = (value: number) =>
  Intl.NumberFormat("en-US", { notation: "compact" }).format(value);

type colorThemeOpts = {
  darkModeBackground: string;
  lightModeBackground: string;
  darkModeGray: string;
};

export const getBackgroundColor = (
  expand: boolean,
  isDarkMode: boolean,
  themeOpts: colorThemeOpts
) => {
  if (expand) {
    if (isDarkMode) {
      return themeOpts.darkModeBackground;
    } else {
      return themeOpts.lightModeBackground;
    }
  } else {
    return isDarkMode ? themeOpts.darkModeGray : undefined;
  }
};

interface colorsMap {
  [key: string]: string;
}

// assumes a `1-based` index.
export const getChartColorByMode = (
  isDarkMode: boolean,
  idx: number,
  colors: colorsMap
) => {
  return colors[`chart_${idx}${isDarkMode ? "_dark" : ""}`];
};

/**
 * Function to create a date range string (for Case Timeline).
 * @param {string} [startDate] the start date
 * @param {string | null | undefined} [endDate] the end date (if exists)
 * @returns {string} the date range string
 */
export const createDateRangeString = (
  startDate: string | null,
  endDate?: string | null
): string => {
  if (!startDate) {
    return "Pending";
  }
  const canShowEndDate = endDate !== undefined;
  return `${formatDate(
    startDate,
    canShowEndDate && checkSameYear(startDate, endDate)
      ? { noYear: true }
      : { shortYear: true }
  )}${
    canShowEndDate
      ? ` - ${
          endDate !== null
            ? formatDate(endDate, { shortYear: true })
            : "Pending"
        }`
      : ""
  }`;
};
