import React, { lazy, Suspense } from "react";
import { CasesCentralDashboardPageProps } from "./CasesCentralDashboardPage";

const LazyCasesCentralDashboardPage = lazy(() => import("./CasesCentralDashboardPage"));

/**
 * Lazy component for CasesCentralDashboardPage.
 * @param {CasesCentralDashboardPageProps} [props] the props
 * @returns {JSX.Element} the lazy component
 */
const CasesCentralDashboardPage: React.FC<CasesCentralDashboardPageProps> = (props) => (
  <Suspense fallback={null}>
    <LazyCasesCentralDashboardPage {...props} />
  </Suspense>
);

export default CasesCentralDashboardPage;
