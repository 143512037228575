import React, { lazy, Suspense } from "react";
import { ClaimDetailsPageProps } from "./ClaimDetailsPage";

const LazyClaimDetailsPage = lazy(() => import("./ClaimDetailsPage"));

/**
 * Lazy component for ClaimDetailsPage.
 * @param {ClaimDetailsPageProps} [props] the props
 * @returns {JSX.Element} the lazy component
 */
const ClaimDetailsPage: React.FC<ClaimDetailsPageProps> = (props) => (
  <Suspense fallback={null}>
    <LazyClaimDetailsPage {...props} />
  </Suspense>
);

export default ClaimDetailsPage;
