import React from "react";
import Column from "@amzn/meridian/column";
import Alert from "@amzn/meridian/alert";

export interface ErrorMessageProps {
  title?: string;
  message: string | JSX.Element;
}

/**
 * Alert to show if an error occurs.
 * @param {ErrorMessageProps} [props] the props
 * @returns {JSX.Element} the component
 */
const ErrorMessage: React.FC<ErrorMessageProps> = ({ title, message }) => (
  <Column spacing="500">
    <Alert type="error" title={title} size="large">
      {message}
    </Alert>
  </Column>
);

export default ErrorMessage;
