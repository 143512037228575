import React, { lazy, Suspense } from "react";
import { CasesViewPageProps } from "./CasesViewPage";

const LazyCasesViewPage = lazy(() => import("./CasesViewPage"));

/**
 * Lazy component for CasesViewPage.
 * @param {CasesViewPageProps} [props] the props
 * @returns {JSX.Element} the lazy component
 */
const CasesViewPage: React.FC<CasesViewPageProps> = (props) => (
  <Suspense fallback={null}>
    <LazyCasesViewPage {...props} />
  </Suspense>
);

export default CasesViewPage;
