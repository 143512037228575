import { Auth, Hub } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import React, { useCallback, useEffect, useState } from "react";

/** Properties for App component. */
interface AuthProps {
  user: CognitoUser;
}

/**
 * Function to wrap the component with user authentication.
 * @param {React.FC} [WrappedComponent] the component to wrap
 * @returns
 */
const WithAuthenticator = <P extends Object>(
  Component: React.ComponentType<P & AuthProps>
): React.FC<P> => {
  /**
   * The wrapped component with authentication.
   * @param {P} [props] the props
   * @returns {JSX.Element} the component
   */
  const WrappedComponent: React.FC<P> = (props: P) => {
    const [checking, setChecking] = useState(true);
    const [user, setUser] = useState<CognitoUser | undefined>(undefined);

    /**
     * Callback function to fetch the user right after signing in.
     */
    const fetchUserOnSignIn = useCallback(async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        setUser(currentUser);
      } catch (error) {
        // handle error
        console.error(error);
      } finally {
        setChecking(false);
      }
    }, []);

    /**
     * Callback function to fetch the user on loading the application.
     */
    const fetchUserOnLoad = useCallback(async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const session = await Auth.currentSession();
        // Check the session.
        if (session.isValid()) {
          setUser(currentUser);
        }
      } catch (error) {
        console.error(error);
        // This initiates the Midway sign in.
        // Also send what URL the user was trying to enter before.
        Auth.federatedSignIn({
          customProvider: "FederateOIDC",
          customState: window.location.href,
        });
      } finally {
        setChecking(false);
      }
    }, []);

    useEffect(() => {
      // Set up the Hub to listen to auth events.
      Hub.listen("auth", ({ payload: { event, data } }) => {
        switch (event) {
          case "signIn":
            fetchUserOnSignIn();
            break;
          case "signOut":
            setUser(undefined);
            break;
          case "customOAuthState":
            // Custom State will be the URL the user was trying to go to.
            window.location.replace(data);
            break;
          case "signIn_failure":
          case "tokenRefresh_failure":
            // Check if any failures occured.
            console.error(`Sign in failure (${event})`, data);
            fetchUserOnLoad();
            break;
          default:
            // Includes token refresh.
            fetchUserOnLoad();
            break;
        }
      });

      // Check if the user should still be logged in.
      fetchUserOnLoad();
    }, [fetchUserOnSignIn, fetchUserOnLoad]);

    return checking || !user ? <></> : <Component {...props} user={user} />;
  };

  return WrappedComponent;
};

export default WithAuthenticator;
