/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAssociatedCasesForClaim = /* GraphQL */ `
  query GetAssociatedCasesForClaim(
    $claimNumber: String!
    $employeeId: String
    $incidentId: String
  ) {
    getAssociatedCasesForClaim(
      claimNumber: $claimNumber
      employeeId: $employeeId
      incidentId: $incidentId
    ) {
      amazonAlias
      associatedClaim {
        additionalClaimStatus
        amazonAlias
        austinMedicalStatus
        badgeColor
        bodySide
        caseNumber
        cause
        causeGeneral
        claimNumber
        claimOverDue
        claimSubStatus
        claimType
        claimant
        closedDate
        createdAt
        detailBodyPart
        detailNature
        employeeId
        eventDescription
        hireDate
        inLitigation
        incidentId
        injuryDescription
        jurisdictionState
        lossDate
        lossDescription
        matchedDate
        open180Days
        openedDate
        org
        processAt
        receivedDate
        recommendedCases
        reconciliationStatus
        region
        reopenedDate
        reportedDate
        site
        siteScore
        subOrg
        subRegion
        superRegion
        totalClaims
        updatedAt
        updatedBy
      }
      austinOutcome
      badgeColor
      caseClosedDate
      caseInsights {
        insightDescription
        insightErrorCode
        insightName
        insightOutcome
        insightSeverity
        insightType
        prediction
        reason
      }
      caseNumber
      claimCaseAnalysis {
        bodyPartScore
        empNameScore
        lossDateDiff
        lossDateDiffScore
        siteScore
        totalScore
      }
      claimMatchStatus
      claimNumber
      closedMedicalStatusDate
      contributingObject
      createdAt
      dafwDateRanges {
        endDate
        startDate
      }
      dafwEndDate
      dafwStartDate
      dateRecorded
      dateReported
      detailBodyPart
      dispositionPostEncounterType
      dispositionPostEncounterTypeSecondary
      dtosEndDate
      dtosStartDate
      employeeName
      employeeType
      endtDate
      filesAttached
      flagCount
      incidentDate
      incidentDescription
      incidentId
      incidentLocation
      incidentMedicalStatus
      incidentSeverity
      incidentTime
      incidentType
      initialAssessmentDetails
      initialReportAttached
      injuryType
      isPending
      laborShareInd
      medicalStatus
      nonWorkRelated
      numberOfDays
      org
      oshaShortDescription
      primaryImpact
      primaryObject
      principalBodyPart
      privacyCase
      recordableCriteria
      recordableIndicator
      referredToDate
      region
      rwaDateRanges {
        endDate
        startDate
      }
      rwaEndDate
      rwaStartDate
      secondaryImpact
      shiftStartTime
      site
      siteCountry
      siteOrg
      siteRegion
      siteState
      siteSubOrg
      startDate
      status
      subRegion
      superRegion
      timeReported
      timeZone
      totalCases
      updatedAt
      warnCount
      workRelatedException
    }
  }
`;
export const getAssociatedProjectsForSI = /* GraphQL */ `
  query GetAssociatedProjectsForSI($incidentId: String) {
    getAssociatedProjectsForSI(incidentId: $incidentId) {
      detailedBodyPart
      engineeringAdministrativePPE
      keywords
      location
      org
      primaryImpact
      primaryObject
      principalBodyPart
      processPath
      projectLink
      projectName
      projectSIAnalysis {
        bodyMatchCount
        detailedBodyPartMatch
        eap
        incidentId
        incidentLocationMatch
        matchCount
        matchCountProportion
        primaryImpactMatch
        primaryObjectMatch
        principalBodyPartMatch
        processPathMatch
        projectName
        projectRecommendation
        projectRulesCounter
        secondaryImpactMatch
        secondaryObjectMatch
      }
      secondaryImpact
      secondaryObject
      siteType
      status
      subOrg
      totalProjects
    }
  }
`;
export const getCase = /* GraphQL */ `
  query GetCase($incidentId: String!, $site: String) {
    getCase(incidentId: $incidentId, site: $site) {
      amazonAlias
      associatedClaim {
        additionalClaimStatus
        amazonAlias
        austinMedicalStatus
        badgeColor
        bodySide
        caseNumber
        cause
        causeGeneral
        claimNumber
        claimOverDue
        claimSubStatus
        claimType
        claimant
        closedDate
        createdAt
        detailBodyPart
        detailNature
        employeeId
        eventDescription
        hireDate
        inLitigation
        incidentId
        injuryDescription
        jurisdictionState
        lossDate
        lossDescription
        matchedDate
        open180Days
        openedDate
        org
        processAt
        receivedDate
        recommendedCases
        reconciliationStatus
        region
        reopenedDate
        reportedDate
        site
        siteScore
        subOrg
        subRegion
        superRegion
        totalClaims
        updatedAt
        updatedBy
      }
      austinOutcome
      badgeColor
      caseClosedDate
      caseInsights {
        insightDescription
        insightErrorCode
        insightName
        insightOutcome
        insightSeverity
        insightType
        prediction
        reason
      }
      caseNumber
      claimCaseAnalysis {
        bodyPartScore
        empNameScore
        lossDateDiff
        lossDateDiffScore
        siteScore
        totalScore
      }
      claimMatchStatus
      claimNumber
      closedMedicalStatusDate
      contributingObject
      createdAt
      dafwDateRanges {
        endDate
        startDate
      }
      dafwEndDate
      dafwStartDate
      dateRecorded
      dateReported
      detailBodyPart
      dispositionPostEncounterType
      dispositionPostEncounterTypeSecondary
      dtosEndDate
      dtosStartDate
      employeeName
      employeeType
      endtDate
      filesAttached
      flagCount
      incidentDate
      incidentDescription
      incidentId
      incidentLocation
      incidentMedicalStatus
      incidentSeverity
      incidentTime
      incidentType
      initialAssessmentDetails
      initialReportAttached
      injuryType
      isPending
      laborShareInd
      medicalStatus
      nonWorkRelated
      numberOfDays
      org
      oshaShortDescription
      primaryImpact
      primaryObject
      principalBodyPart
      privacyCase
      recordableCriteria
      recordableIndicator
      referredToDate
      region
      rwaDateRanges {
        endDate
        startDate
      }
      rwaEndDate
      rwaStartDate
      secondaryImpact
      shiftStartTime
      site
      siteCountry
      siteOrg
      siteRegion
      siteState
      siteSubOrg
      startDate
      status
      subRegion
      superRegion
      timeReported
      timeZone
      totalCases
      updatedAt
      warnCount
      workRelatedException
    }
  }
`;
export const getCaseDev = /* GraphQL */ `
  query GetCaseDev(
    $after: Int!
    $amazonAlias: String
    $austinOutcome: String
    $caseNumber: String
    $documentType: String
    $endDate: String
    $filesAttached: String
    $first: Int!
    $insightPriority: String
    $insightStatus: String
    $orderType: String
    $org: String!
    $outstandingStatus: String
    $overdueStatus: String
    $recordable: String
    $region: String
    $site: String
    $siteType: String
    $startDate: String
    $subOrg: String
    $subRegion: String
    $superRegion: String
  ) {
    getCaseDev(
      after: $after
      amazonAlias: $amazonAlias
      austinOutcome: $austinOutcome
      caseNumber: $caseNumber
      documentType: $documentType
      endDate: $endDate
      filesAttached: $filesAttached
      first: $first
      insightPriority: $insightPriority
      insightStatus: $insightStatus
      orderType: $orderType
      org: $org
      outstandingStatus: $outstandingStatus
      overdueStatus: $overdueStatus
      recordable: $recordable
      region: $region
      site: $site
      siteType: $siteType
      startDate: $startDate
      subOrg: $subOrg
      subRegion: $subRegion
      superRegion: $superRegion
    ) {
      amazonAlias
      associatedClaim {
        additionalClaimStatus
        amazonAlias
        austinMedicalStatus
        badgeColor
        bodySide
        caseNumber
        cause
        causeGeneral
        claimNumber
        claimOverDue
        claimSubStatus
        claimType
        claimant
        closedDate
        createdAt
        detailBodyPart
        detailNature
        employeeId
        eventDescription
        hireDate
        inLitigation
        incidentId
        injuryDescription
        jurisdictionState
        lossDate
        lossDescription
        matchedDate
        open180Days
        openedDate
        org
        processAt
        receivedDate
        recommendedCases
        reconciliationStatus
        region
        reopenedDate
        reportedDate
        site
        siteScore
        subOrg
        subRegion
        superRegion
        totalClaims
        updatedAt
        updatedBy
      }
      austinOutcome
      badgeColor
      caseClosedDate
      caseInsights {
        insightDescription
        insightErrorCode
        insightName
        insightOutcome
        insightSeverity
        insightType
        prediction
        reason
      }
      caseNumber
      claimCaseAnalysis {
        bodyPartScore
        empNameScore
        lossDateDiff
        lossDateDiffScore
        siteScore
        totalScore
      }
      claimMatchStatus
      claimNumber
      closedMedicalStatusDate
      contributingObject
      createdAt
      dafwDateRanges {
        endDate
        startDate
      }
      dafwEndDate
      dafwStartDate
      dateRecorded
      dateReported
      detailBodyPart
      dispositionPostEncounterType
      dispositionPostEncounterTypeSecondary
      dtosEndDate
      dtosStartDate
      employeeName
      employeeType
      endtDate
      filesAttached
      flagCount
      incidentDate
      incidentDescription
      incidentId
      incidentLocation
      incidentMedicalStatus
      incidentSeverity
      incidentTime
      incidentType
      initialAssessmentDetails
      initialReportAttached
      injuryType
      isPending
      laborShareInd
      medicalStatus
      nonWorkRelated
      numberOfDays
      org
      oshaShortDescription
      primaryImpact
      primaryObject
      principalBodyPart
      privacyCase
      recordableCriteria
      recordableIndicator
      referredToDate
      region
      rwaDateRanges {
        endDate
        startDate
      }
      rwaEndDate
      rwaStartDate
      secondaryImpact
      shiftStartTime
      site
      siteCountry
      siteOrg
      siteRegion
      siteState
      siteSubOrg
      startDate
      status
      subRegion
      superRegion
      timeReported
      timeZone
      totalCases
      updatedAt
      warnCount
      workRelatedException
    }
  }
`;
export const getCasePaperWorkLagTime = /* GraphQL */ `
  query GetCasePaperWorkLagTime(
    $metricDate: String!
    $metricDateStart: String
    $metricDuration: String!
    $org: String
    $region: String
    $site: String
    $siteType: String
    $subOrg: String
    $subRegion: String
    $superRegion: String
  ) {
    getCasePaperWorkLagTime(
      metricDate: $metricDate
      metricDateStart: $metricDateStart
      metricDuration: $metricDuration
      org: $org
      region: $region
      site: $site
      siteType: $siteType
      subOrg: $subOrg
      subRegion: $subRegion
      superRegion: $superRegion
    ) {
      metricCount
      metricCountry
      metricDimension
      metricOrg
      metricRegion
      metricSite
      metricSubOrg
      metricType
      metricWeek
    }
  }
`;
export const getCaseRecordableStatus = /* GraphQL */ `
  query GetCaseRecordableStatus(
    $metricDate: String!
    $metricDateStart: String
    $metricDuration: String!
    $org: String
    $region: String
    $site: String
    $siteType: String
    $subOrg: String
    $subRegion: String
    $superRegion: String
  ) {
    getCaseRecordableStatus(
      metricDate: $metricDate
      metricDateStart: $metricDateStart
      metricDuration: $metricDuration
      org: $org
      region: $region
      site: $site
      siteType: $siteType
      subOrg: $subOrg
      subRegion: $subRegion
      superRegion: $superRegion
    ) {
      metricCount
      metricCountry
      metricDimension
      metricOrg
      metricRegion
      metricSite
      metricSubOrg
      metricType
      metricWeek
    }
  }
`;
export const getCaseSeverityBreakdown = /* GraphQL */ `
  query GetCaseSeverityBreakdown(
    $metricDate: String!
    $metricDateStart: String
    $metricDuration: String!
    $org: String
    $region: String
    $site: String
    $siteType: String
    $subOrg: String
    $subRegion: String
    $superRegion: String
  ) {
    getCaseSeverityBreakdown(
      metricDate: $metricDate
      metricDateStart: $metricDateStart
      metricDuration: $metricDuration
      org: $org
      region: $region
      site: $site
      siteType: $siteType
      subOrg: $subOrg
      subRegion: $subRegion
      superRegion: $superRegion
    ) {
      metricCount
      metricCountry
      metricDimension
      metricOrg
      metricRegion
      metricSite
      metricSubOrg
      metricType
      metricWeek
    }
  }
`;
export const getCaseWithGenAI = /* GraphQL */ `
  query GetCaseWithGenAI($incidentId: String!, $site: String) {
    getCaseWithGenAI(incidentId: $incidentId, site: $site) {
      amazonAlias
      associatedClaim {
        additionalClaimStatus
        amazonAlias
        austinMedicalStatus
        badgeColor
        bodySide
        caseNumber
        cause
        causeGeneral
        claimNumber
        claimOverDue
        claimSubStatus
        claimType
        claimant
        closedDate
        createdAt
        detailBodyPart
        detailNature
        employeeId
        eventDescription
        hireDate
        inLitigation
        incidentId
        injuryDescription
        jurisdictionState
        lossDate
        lossDescription
        matchedDate
        open180Days
        openedDate
        org
        processAt
        receivedDate
        recommendedCases
        reconciliationStatus
        region
        reopenedDate
        reportedDate
        site
        siteScore
        subOrg
        subRegion
        superRegion
        totalClaims
        updatedAt
        updatedBy
      }
      austinOutcome
      badgeColor
      caseClosedDate
      caseInsights {
        insightDescription
        insightErrorCode
        insightName
        insightOutcome
        insightSeverity
        insightType
        prediction
        reason
      }
      caseNumber
      claimCaseAnalysis {
        bodyPartScore
        empNameScore
        lossDateDiff
        lossDateDiffScore
        siteScore
        totalScore
      }
      claimMatchStatus
      claimNumber
      closedMedicalStatusDate
      contributingObject
      createdAt
      dafwDateRanges {
        endDate
        startDate
      }
      dafwEndDate
      dafwStartDate
      dateRecorded
      dateReported
      detailBodyPart
      dispositionPostEncounterType
      dispositionPostEncounterTypeSecondary
      dtosEndDate
      dtosStartDate
      employeeName
      employeeType
      endtDate
      filesAttached
      flagCount
      incidentDate
      incidentDescription
      incidentId
      incidentLocation
      incidentMedicalStatus
      incidentSeverity
      incidentTime
      incidentType
      initialAssessmentDetails
      initialReportAttached
      injuryType
      isPending
      laborShareInd
      medicalStatus
      nonWorkRelated
      numberOfDays
      org
      oshaShortDescription
      primaryImpact
      primaryObject
      principalBodyPart
      privacyCase
      recordableCriteria
      recordableIndicator
      referredToDate
      region
      rwaDateRanges {
        endDate
        startDate
      }
      rwaEndDate
      rwaStartDate
      secondaryImpact
      shiftStartTime
      site
      siteCountry
      siteOrg
      siteRegion
      siteState
      siteSubOrg
      startDate
      status
      subRegion
      superRegion
      timeReported
      timeZone
      totalCases
      updatedAt
      warnCount
      workRelatedException
    }
  }
`;
export const getCases = /* GraphQL */ `
  query GetCases(
    $after: Int!
    $amazonAlias: String
    $austinOutcome: String
    $caseNumber: String
    $documentEndDate: String
    $documentStartDate: String
    $documentType: String
    $endDate: String
    $filesAttached: String
    $first: Int!
    $insightBucketName: bucketNameEnum
    $insightPriority: String
    $insightStatus: String
    $insightType: String
    $orderType: String
    $org: String!
    $outstandingStatus: String
    $overdueStatus: String
    $recordable: String
    $region: String
    $site: String
    $siteType: String
    $startDate: String
    $subOrg: String
    $subRegion: String
    $superRegion: String
  ) {
    getCases(
      after: $after
      amazonAlias: $amazonAlias
      austinOutcome: $austinOutcome
      caseNumber: $caseNumber
      documentEndDate: $documentEndDate
      documentStartDate: $documentStartDate
      documentType: $documentType
      endDate: $endDate
      filesAttached: $filesAttached
      first: $first
      insightBucketName: $insightBucketName
      insightPriority: $insightPriority
      insightStatus: $insightStatus
      insightType: $insightType
      orderType: $orderType
      org: $org
      outstandingStatus: $outstandingStatus
      overdueStatus: $overdueStatus
      recordable: $recordable
      region: $region
      site: $site
      siteType: $siteType
      startDate: $startDate
      subOrg: $subOrg
      subRegion: $subRegion
      superRegion: $superRegion
    ) {
      amazonAlias
      associatedClaim {
        additionalClaimStatus
        amazonAlias
        austinMedicalStatus
        badgeColor
        bodySide
        caseNumber
        cause
        causeGeneral
        claimNumber
        claimOverDue
        claimSubStatus
        claimType
        claimant
        closedDate
        createdAt
        detailBodyPart
        detailNature
        employeeId
        eventDescription
        hireDate
        inLitigation
        incidentId
        injuryDescription
        jurisdictionState
        lossDate
        lossDescription
        matchedDate
        open180Days
        openedDate
        org
        processAt
        receivedDate
        recommendedCases
        reconciliationStatus
        region
        reopenedDate
        reportedDate
        site
        siteScore
        subOrg
        subRegion
        superRegion
        totalClaims
        updatedAt
        updatedBy
      }
      austinOutcome
      badgeColor
      caseClosedDate
      caseInsights {
        insightDescription
        insightErrorCode
        insightName
        insightOutcome
        insightSeverity
        insightType
        prediction
        reason
      }
      caseNumber
      claimCaseAnalysis {
        bodyPartScore
        empNameScore
        lossDateDiff
        lossDateDiffScore
        siteScore
        totalScore
      }
      claimMatchStatus
      claimNumber
      closedMedicalStatusDate
      contributingObject
      createdAt
      dafwDateRanges {
        endDate
        startDate
      }
      dafwEndDate
      dafwStartDate
      dateRecorded
      dateReported
      detailBodyPart
      dispositionPostEncounterType
      dispositionPostEncounterTypeSecondary
      dtosEndDate
      dtosStartDate
      employeeName
      employeeType
      endtDate
      filesAttached
      flagCount
      incidentDate
      incidentDescription
      incidentId
      incidentLocation
      incidentMedicalStatus
      incidentSeverity
      incidentTime
      incidentType
      initialAssessmentDetails
      initialReportAttached
      injuryType
      isPending
      laborShareInd
      medicalStatus
      nonWorkRelated
      numberOfDays
      org
      oshaShortDescription
      primaryImpact
      primaryObject
      principalBodyPart
      privacyCase
      recordableCriteria
      recordableIndicator
      referredToDate
      region
      rwaDateRanges {
        endDate
        startDate
      }
      rwaEndDate
      rwaStartDate
      secondaryImpact
      shiftStartTime
      site
      siteCountry
      siteOrg
      siteRegion
      siteState
      siteSubOrg
      startDate
      status
      subRegion
      superRegion
      timeReported
      timeZone
      totalCases
      updatedAt
      warnCount
      workRelatedException
    }
  }
`;
export const getCasesWithGenAI = /* GraphQL */ `
  query GetCasesWithGenAI(
    $after: Int!
    $amazonAlias: String
    $austinOutcome: String
    $caseNumber: String
    $documentEndDate: String
    $documentStartDate: String
    $documentType: String
    $endDate: String
    $filesAttached: String
    $first: Int!
    $insightBucketName: bucketNameEnum
    $insightPriority: String
    $insightStatus: String
    $insightType: String
    $orderType: String
    $org: String!
    $outstandingStatus: String
    $overdueStatus: String
    $recordable: String
    $region: String
    $site: String
    $siteType: String
    $startDate: String
    $subOrg: String
    $subRegion: String
    $superRegion: String
  ) {
    getCasesWithGenAI(
      after: $after
      amazonAlias: $amazonAlias
      austinOutcome: $austinOutcome
      caseNumber: $caseNumber
      documentEndDate: $documentEndDate
      documentStartDate: $documentStartDate
      documentType: $documentType
      endDate: $endDate
      filesAttached: $filesAttached
      first: $first
      insightBucketName: $insightBucketName
      insightPriority: $insightPriority
      insightStatus: $insightStatus
      insightType: $insightType
      orderType: $orderType
      org: $org
      outstandingStatus: $outstandingStatus
      overdueStatus: $overdueStatus
      recordable: $recordable
      region: $region
      site: $site
      siteType: $siteType
      startDate: $startDate
      subOrg: $subOrg
      subRegion: $subRegion
      superRegion: $superRegion
    ) {
      amazonAlias
      associatedClaim {
        additionalClaimStatus
        amazonAlias
        austinMedicalStatus
        badgeColor
        bodySide
        caseNumber
        cause
        causeGeneral
        claimNumber
        claimOverDue
        claimSubStatus
        claimType
        claimant
        closedDate
        createdAt
        detailBodyPart
        detailNature
        employeeId
        eventDescription
        hireDate
        inLitigation
        incidentId
        injuryDescription
        jurisdictionState
        lossDate
        lossDescription
        matchedDate
        open180Days
        openedDate
        org
        processAt
        receivedDate
        recommendedCases
        reconciliationStatus
        region
        reopenedDate
        reportedDate
        site
        siteScore
        subOrg
        subRegion
        superRegion
        totalClaims
        updatedAt
        updatedBy
      }
      austinOutcome
      badgeColor
      caseClosedDate
      caseInsights {
        insightDescription
        insightErrorCode
        insightName
        insightOutcome
        insightSeverity
        insightType
        prediction
        reason
      }
      caseNumber
      claimCaseAnalysis {
        bodyPartScore
        empNameScore
        lossDateDiff
        lossDateDiffScore
        siteScore
        totalScore
      }
      claimMatchStatus
      claimNumber
      closedMedicalStatusDate
      contributingObject
      createdAt
      dafwDateRanges {
        endDate
        startDate
      }
      dafwEndDate
      dafwStartDate
      dateRecorded
      dateReported
      detailBodyPart
      dispositionPostEncounterType
      dispositionPostEncounterTypeSecondary
      dtosEndDate
      dtosStartDate
      employeeName
      employeeType
      endtDate
      filesAttached
      flagCount
      incidentDate
      incidentDescription
      incidentId
      incidentLocation
      incidentMedicalStatus
      incidentSeverity
      incidentTime
      incidentType
      initialAssessmentDetails
      initialReportAttached
      injuryType
      isPending
      laborShareInd
      medicalStatus
      nonWorkRelated
      numberOfDays
      org
      oshaShortDescription
      primaryImpact
      primaryObject
      principalBodyPart
      privacyCase
      recordableCriteria
      recordableIndicator
      referredToDate
      region
      rwaDateRanges {
        endDate
        startDate
      }
      rwaEndDate
      rwaStartDate
      secondaryImpact
      shiftStartTime
      site
      siteCountry
      siteOrg
      siteRegion
      siteState
      siteSubOrg
      startDate
      status
      subRegion
      superRegion
      timeReported
      timeZone
      totalCases
      updatedAt
      warnCount
      workRelatedException
    }
  }
`;
export const getClaim = /* GraphQL */ `
  query GetClaim($claimNumber: String!, $incidentId: String, $site: String!) {
    getClaim(claimNumber: $claimNumber, incidentId: $incidentId, site: $site) {
      additionalClaimStatus
      amazonAlias
      associatedCase {
        amazonAlias
        austinOutcome
        badgeColor
        caseClosedDate
        caseNumber
        claimMatchStatus
        claimNumber
        closedMedicalStatusDate
        contributingObject
        createdAt
        dafwEndDate
        dafwStartDate
        dateRecorded
        dateReported
        detailBodyPart
        dispositionPostEncounterType
        dispositionPostEncounterTypeSecondary
        dtosEndDate
        dtosStartDate
        employeeName
        employeeType
        endtDate
        filesAttached
        flagCount
        incidentDate
        incidentDescription
        incidentId
        incidentLocation
        incidentMedicalStatus
        incidentSeverity
        incidentTime
        incidentType
        initialAssessmentDetails
        initialReportAttached
        injuryType
        isPending
        laborShareInd
        medicalStatus
        nonWorkRelated
        numberOfDays
        org
        oshaShortDescription
        primaryImpact
        primaryObject
        principalBodyPart
        privacyCase
        recordableCriteria
        recordableIndicator
        referredToDate
        region
        rwaEndDate
        rwaStartDate
        secondaryImpact
        shiftStartTime
        site
        siteCountry
        siteOrg
        siteRegion
        siteState
        siteSubOrg
        startDate
        status
        subRegion
        superRegion
        timeReported
        timeZone
        totalCases
        updatedAt
        warnCount
        workRelatedException
      }
      austinMedicalStatus
      badgeColor
      bodySide
      caseNumber
      cause
      causeGeneral
      claimNumber
      claimOverDue
      claimSubStatus
      claimType
      claimant
      closedDate
      createdAt
      detailBodyPart
      detailNature
      employeeId
      eventDescription
      hireDate
      inLitigation
      incidentId
      injuryDescription
      jurisdictionState
      lossDate
      lossDescription
      matchedDate
      open180Days
      openedDate
      org
      processAt
      receivedDate
      recommendedCases
      reconciliationStatus
      region
      reopenedDate
      reportedDate
      site
      siteScore
      subOrg
      subRegion
      superRegion
      totalClaims
      updatedAt
      updatedBy
    }
  }
`;
export const getClaimBodyPartCounts = /* GraphQL */ `
  query GetClaimBodyPartCounts(
    $metricDate: String!
    $metricDateStart: String
    $metricDuration: String
    $org: String
    $region: String
    $site: String
    $siteType: String
    $subOrg: String
    $subRegion: String
    $superRegion: String
  ) {
    getClaimBodyPartCounts(
      metricDate: $metricDate
      metricDateStart: $metricDateStart
      metricDuration: $metricDuration
      org: $org
      region: $region
      site: $site
      siteType: $siteType
      subOrg: $subOrg
      subRegion: $subRegion
      superRegion: $superRegion
    ) {
      metricCount
      metricCountry
      metricDimension
      metricOrg
      metricRegion
      metricSite
      metricSubOrg
      metricType
      metricWeek
    }
  }
`;
export const getClaimCounts = /* GraphQL */ `
  query GetClaimCounts($site: String!) {
    getClaimCounts(site: $site) {
      claimsCount
      type
    }
  }
`;
export const getClaimDataMismatchCounts = /* GraphQL */ `
  query GetClaimDataMismatchCounts(
    $metricDate: String!
    $metricDateStart: String
    $metricDuration: String!
    $org: String
    $region: String
    $site: String
    $siteType: String
    $subOrg: String
    $subRegion: String
    $superRegion: String
  ) {
    getClaimDataMismatchCounts(
      metricDate: $metricDate
      metricDateStart: $metricDateStart
      metricDuration: $metricDuration
      org: $org
      region: $region
      site: $site
      siteType: $siteType
      subOrg: $subOrg
      subRegion: $subRegion
      superRegion: $superRegion
    ) {
      metricCount
      metricCountry
      metricDimension
      metricOrg
      metricRegion
      metricSite
      metricSubOrg
      metricType
      metricWeek
    }
  }
`;
export const getClaimSiteHierarchy = /* GraphQL */ `
  query GetClaimSiteHierarchy(
    $org: String
    $region: String
    $site: String
    $siteType: String
    $subOrg: String
    $subRegion: String
    $suborg: String
    $superRegion: String
  ) {
    getClaimSiteHierarchy(
      org: $org
      region: $region
      site: $site
      siteType: $siteType
      subOrg: $subOrg
      subRegion: $subRegion
      suborg: $suborg
      superRegion: $superRegion
    ) {
      isActive
      org
      region
      site
      siteType
      subRegion
      suborg
      superRegion
    }
  }
`;
export const getClaimSitePerformance = /* GraphQL */ `
  query GetClaimSitePerformance(
    $metricDate: String!
    $metricDateStart: String
    $metricDuration: String!
    $org: String
    $region: String
    $site: String
    $siteType: String
    $subOrg: String
    $subRegion: String
    $superRegion: String
  ) {
    getClaimSitePerformance(
      metricDate: $metricDate
      metricDateStart: $metricDateStart
      metricDuration: $metricDuration
      org: $org
      region: $region
      site: $site
      siteType: $siteType
      subOrg: $subOrg
      subRegion: $subRegion
      superRegion: $superRegion
    ) {
      metricCount
      metricCountry
      metricDimension
      metricOrg
      metricRegion
      metricSite
      metricSubOrg
      metricType
      metricWeek
    }
  }
`;
export const getClaimSubStatusByClaimID = /* GraphQL */ `
  query GetClaimSubStatusByClaimID($claimNumber: String!) {
    getClaimSubStatusByClaimID(claimNumber: $claimNumber) {
      subStatus
    }
  }
`;
export const getClaimSuperRegions = /* GraphQL */ `
  query GetClaimSuperRegions($first: Int) {
    getClaimSuperRegions(first: $first) {
      isActive
      org
      region
      site
      siteType
      subRegion
      suborg
      superRegion
    }
  }
`;
export const getClaims = /* GraphQL */ `
  query GetClaims(
    $after: Int!
    $amazonAlias: String
    $austinMedicalStatus: String
    $claimNumber: String
    $claimStatus: String
    $endDate: String
    $first: Int!
    $org: String!
    $receivedEndDate: String
    $receivedStartDate: String
    $reconStatus: String!
    $region: String
    $reportedEndDate: String
    $reportedStartDate: String
    $site: String
    $siteType: String
    $startDate: String
    $subOrg: String
    $subRegion: String
    $superRegion: String
    $unmatchStatus: UnmatchStatus
  ) {
    getClaims(
      after: $after
      amazonAlias: $amazonAlias
      austinMedicalStatus: $austinMedicalStatus
      claimNumber: $claimNumber
      claimStatus: $claimStatus
      endDate: $endDate
      first: $first
      org: $org
      receivedEndDate: $receivedEndDate
      receivedStartDate: $receivedStartDate
      reconStatus: $reconStatus
      region: $region
      reportedEndDate: $reportedEndDate
      reportedStartDate: $reportedStartDate
      site: $site
      siteType: $siteType
      startDate: $startDate
      subOrg: $subOrg
      subRegion: $subRegion
      superRegion: $superRegion
      unmatchStatus: $unmatchStatus
    ) {
      additionalClaimStatus
      amazonAlias
      associatedCase {
        amazonAlias
        austinOutcome
        badgeColor
        caseClosedDate
        caseNumber
        claimMatchStatus
        claimNumber
        closedMedicalStatusDate
        contributingObject
        createdAt
        dafwEndDate
        dafwStartDate
        dateRecorded
        dateReported
        detailBodyPart
        dispositionPostEncounterType
        dispositionPostEncounterTypeSecondary
        dtosEndDate
        dtosStartDate
        employeeName
        employeeType
        endtDate
        filesAttached
        flagCount
        incidentDate
        incidentDescription
        incidentId
        incidentLocation
        incidentMedicalStatus
        incidentSeverity
        incidentTime
        incidentType
        initialAssessmentDetails
        initialReportAttached
        injuryType
        isPending
        laborShareInd
        medicalStatus
        nonWorkRelated
        numberOfDays
        org
        oshaShortDescription
        primaryImpact
        primaryObject
        principalBodyPart
        privacyCase
        recordableCriteria
        recordableIndicator
        referredToDate
        region
        rwaEndDate
        rwaStartDate
        secondaryImpact
        shiftStartTime
        site
        siteCountry
        siteOrg
        siteRegion
        siteState
        siteSubOrg
        startDate
        status
        subRegion
        superRegion
        timeReported
        timeZone
        totalCases
        updatedAt
        warnCount
        workRelatedException
      }
      austinMedicalStatus
      badgeColor
      bodySide
      caseNumber
      cause
      causeGeneral
      claimNumber
      claimOverDue
      claimSubStatus
      claimType
      claimant
      closedDate
      createdAt
      detailBodyPart
      detailNature
      employeeId
      eventDescription
      hireDate
      inLitigation
      incidentId
      injuryDescription
      jurisdictionState
      lossDate
      lossDescription
      matchedDate
      open180Days
      openedDate
      org
      processAt
      receivedDate
      recommendedCases
      reconciliationStatus
      region
      reopenedDate
      reportedDate
      site
      siteScore
      subOrg
      subRegion
      superRegion
      totalClaims
      updatedAt
      updatedBy
    }
  }
`;
export const getClaimsByPotentialMatches = /* GraphQL */ `
  query GetClaimsByPotentialMatches(
    $metricDate: String
    $metricDateStart: String
    $metricDuration: String!
    $org: String
    $receivedAtEndDate: String
    $receivedAtStartDate: String
    $region: String
    $site: String
    $siteType: String
    $subOrg: String
    $subRegion: String
    $superRegion: String
  ) {
    getClaimsByPotentialMatches(
      metricDate: $metricDate
      metricDateStart: $metricDateStart
      metricDuration: $metricDuration
      org: $org
      receivedAtEndDate: $receivedAtEndDate
      receivedAtStartDate: $receivedAtStartDate
      region: $region
      site: $site
      siteType: $siteType
      subOrg: $subOrg
      subRegion: $subRegion
      superRegion: $superRegion
    ) {
      metricCount
      metricCountry
      metricDimension
      metricOrg
      metricRegion
      metricSite
      metricSubOrg
      metricType
      metricWeek
    }
  }
`;
export const getClaimsForDuration = /* GraphQL */ `
  query GetClaimsForDuration(
    $metricDate: String!
    $metricDateStart: String
    $metricDuration: String!
    $org: String
    $region: String
    $site: String
    $siteType: String
    $subOrg: String
    $subRegion: String
    $superRegion: String
  ) {
    getClaimsForDuration(
      metricDate: $metricDate
      metricDateStart: $metricDateStart
      metricDuration: $metricDuration
      org: $org
      region: $region
      site: $site
      siteType: $siteType
      subOrg: $subOrg
      subRegion: $subRegion
      superRegion: $superRegion
    ) {
      metricCount
      metricCountry
      metricDimension
      metricOrg
      metricRegion
      metricSite
      metricSubOrg
      metricType
      metricWeek
    }
  }
`;
export const getCurrentUserFirstLogin = /* GraphQL */ `
  query GetCurrentUserFirstLogin {
    getCurrentUserFirstLogin
  }
`;
export const getDaysOutcomes = /* GraphQL */ `
  query GetDaysOutcomes(
    $metricDate: String!
    $metricDateStart: String
    $metricDuration: String!
    $org: String
    $region: String
    $site: String
    $siteType: String
    $subOrg: String
    $subRegion: String
    $superRegion: String
  ) {
    getDaysOutcomes(
      metricDate: $metricDate
      metricDateStart: $metricDateStart
      metricDuration: $metricDuration
      org: $org
      region: $region
      site: $site
      siteType: $siteType
      subOrg: $subOrg
      subRegion: $subRegion
      superRegion: $superRegion
    ) {
      metricCount
      metricCountry
      metricDimension
      metricOrg
      metricRegion
      metricSite
      metricSubOrg
      metricType
      metricWeek
    }
  }
`;
export const getDocumentTypes = /* GraphQL */ `
  query GetDocumentTypes {
    getDocumentTypes {
      description
    }
  }
`;
export const getDocumentsByType = /* GraphQL */ `
  query GetDocumentsByType(
    $metricDate: String!
    $metricDateStart: String
    $metricDuration: String!
    $org: String
    $region: String
    $site: String
    $siteType: String
    $subOrg: String
    $subRegion: String
    $superRegion: String
  ) {
    getDocumentsByType(
      metricDate: $metricDate
      metricDateStart: $metricDateStart
      metricDuration: $metricDuration
      org: $org
      region: $region
      site: $site
      siteType: $siteType
      subOrg: $subOrg
      subRegion: $subRegion
      superRegion: $superRegion
    ) {
      metricCount
      metricCountry
      metricDimension
      metricOrg
      metricRegion
      metricSite
      metricSubOrg
      metricType
      metricWeek
    }
  }
`;
export const getFeatureFlagSettings = /* GraphQL */ `
  query GetFeatureFlagSettings {
    getFeatureFlagSettings {
      additionalData
      allowToggle
      forceMode
      name
      role
    }
  }
`;
export const getIncidentIdByCaseNumber = /* GraphQL */ `
  query GetIncidentIdByCaseNumber($caseNumber: String) {
    getIncidentIdByCaseNumber(caseNumber: $caseNumber)
  }
`;
export const getInsightsByType = /* GraphQL */ `
  query GetInsightsByType(
    $bucketName: bucketNameEnum
    $metricDate: String!
    $metricDateStart: String
    $metricDuration: String!
    $org: String
    $region: String
    $site: String
    $siteType: String
    $subOrg: String
    $subRegion: String
    $superRegion: String
  ) {
    getInsightsByType(
      bucketName: $bucketName
      metricDate: $metricDate
      metricDateStart: $metricDateStart
      metricDuration: $metricDuration
      org: $org
      region: $region
      site: $site
      siteType: $siteType
      subOrg: $subOrg
      subRegion: $subRegion
      superRegion: $superRegion
    ) {
      metricCount
      metricCountry
      metricDimension
      metricOrg
      metricRegion
      metricSite
      metricSubOrg
      metricType
      metricWeek
    }
  }
`;
export const getInsightsByTypeWithGenAI = /* GraphQL */ `
  query GetInsightsByTypeWithGenAI(
    $bucketName: bucketNameEnum
    $metricDate: String!
    $metricDateStart: String
    $metricDuration: String!
    $org: String
    $region: String
    $site: String
    $siteType: String
    $subOrg: String
    $subRegion: String
    $superRegion: String
  ) {
    getInsightsByTypeWithGenAI(
      bucketName: $bucketName
      metricDate: $metricDate
      metricDateStart: $metricDateStart
      metricDuration: $metricDuration
      org: $org
      region: $region
      site: $site
      siteType: $siteType
      subOrg: $subOrg
      subRegion: $subRegion
      superRegion: $superRegion
    ) {
      metricCount
      metricCountry
      metricDimension
      metricOrg
      metricRegion
      metricSite
      metricSubOrg
      metricType
      metricWeek
    }
  }
`;
export const getInsightsTypes = /* GraphQL */ `
  query GetInsightsTypes {
    getInsightsTypes {
      description
    }
  }
`;
export const getMedicalDocumentLink = /* GraphQL */ `
  query GetMedicalDocumentLink($documentId: String) {
    getMedicalDocumentLink(documentId: $documentId)
  }
`;
export const getMedicalDocuments = /* GraphQL */ `
  query GetMedicalDocuments($incidentId: String) {
    getMedicalDocuments(incidentId: $incidentId) {
      createdAt
      diagnosis
      docType
      endDate
      id
      incidentClassification
      incidentId
      startDate
      title
    }
  }
`;
export const getMedicalDocumentsList = /* GraphQL */ `
  query GetMedicalDocumentsList($incidentId: String) {
    getMedicalDocumentsList(incidentId: $incidentId) {
      result {
        createdAt
        diagnosis
        docType
        endDate
        id
        incidentClassification
        incidentId
        startDate
        title
      }
    }
  }
`;
export const getOutcomesTrends = /* GraphQL */ `
  query GetOutcomesTrends(
    $metricDate: String!
    $metricDateStart: String
    $metricDuration: String!
    $org: String
    $region: String
    $site: String
    $siteType: String
    $subOrg: String
    $subRegion: String
    $superRegion: String
  ) {
    getOutcomesTrends(
      metricDate: $metricDate
      metricDateStart: $metricDateStart
      metricDuration: $metricDuration
      org: $org
      region: $region
      site: $site
      siteType: $siteType
      subOrg: $subOrg
      subRegion: $subRegion
      superRegion: $superRegion
    ) {
      metricCount
      metricCountry
      metricDimension
      metricOrg
      metricRegion
      metricSite
      metricSubOrg
      metricType
      metricWeek
    }
  }
`;
export const getProjects = /* GraphQL */ `
  query GetProjects(
    $after: Int!
    $first: Int!
    $location: String
    $org: String
    $primaryImpact: String
    $primaryObject: String
    $processPath: String
    $projectName: String
    $status: String
  ) {
    getProjects(
      after: $after
      first: $first
      location: $location
      org: $org
      primaryImpact: $primaryImpact
      primaryObject: $primaryObject
      processPath: $processPath
      projectName: $projectName
      status: $status
    ) {
      detailedBodyPart
      engineeringAdministrativePPE
      keywords
      location
      org
      primaryImpact
      primaryObject
      principalBodyPart
      processPath
      projectLink
      projectName
      projectSIAnalysis {
        bodyMatchCount
        detailedBodyPartMatch
        eap
        incidentId
        incidentLocationMatch
        matchCount
        matchCountProportion
        primaryImpactMatch
        primaryObjectMatch
        principalBodyPartMatch
        processPathMatch
        projectName
        projectRecommendation
        projectRulesCounter
        secondaryImpactMatch
        secondaryObjectMatch
      }
      secondaryImpact
      secondaryObject
      siteType
      status
      subOrg
      totalProjects
    }
  }
`;
export const getRecordkeepingIncidentsGraph = /* GraphQL */ `
  query GetRecordkeepingIncidentsGraph(
    $country: String
    $metricDate: String!
    $metricDateStart: String
    $metricDuration: String!
    $org: String
    $site: String
    $siteType: String
    $suborg: String
  ) {
    getRecordkeepingIncidentsGraph(
      country: $country
      metricDate: $metricDate
      metricDateStart: $metricDateStart
      metricDuration: $metricDuration
      org: $org
      site: $site
      siteType: $siteType
      suborg: $suborg
    ) {
      metricCount
      metricCountry
      metricDimension
      metricOrg
      metricRegion
      metricSite
      metricSubOrg
      metricType
      metricWeek
    }
  }
`;
export const getRoles = /* GraphQL */ `
  query GetRoles {
    getRoles {
      Permissions
      organizationLevel
      pk
      sk
    }
  }
`;
export const getSeriousIncident = /* GraphQL */ `
  query GetSeriousIncident($incidentId: String!) {
    getSeriousIncident(incidentId: $incidentId) {
      badgeColor
      caseNumber
      incidentDate
      incidentDescription
      incidentId
      incidentTime
      location
      primaryImpact
      primaryObject
      processPath
      projectAssignment
      projectName
      secondaryImpact
      secondaryObject
      severity
      status
      suborg
      totalIncidents
    }
  }
`;
export const getSeriousIncidents = /* GraphQL */ `
  query GetSeriousIncidents(
    $after: Int!
    $badge: String
    $caseNumber: String
    $endDate: String
    $first: Int!
    $location: String
    $processPath: String
    $projectAssignment: String
    $severity: String
    $startDate: String
  ) {
    getSeriousIncidents(
      after: $after
      badge: $badge
      caseNumber: $caseNumber
      endDate: $endDate
      first: $first
      location: $location
      processPath: $processPath
      projectAssignment: $projectAssignment
      severity: $severity
      startDate: $startDate
    ) {
      badgeColor
      caseNumber
      incidentDate
      incidentDescription
      incidentId
      incidentTime
      location
      primaryImpact
      primaryObject
      processPath
      projectAssignment
      projectName
      secondaryImpact
      secondaryObject
      severity
      status
      suborg
      totalIncidents
    }
  }
`;
export const getSeriousIncidentsByOrg = /* GraphQL */ `
  query GetSeriousIncidentsByOrg(
    $country: String
    $metricDate: String!
    $metricDateStart: String
    $metricDuration: String!
    $org: String
    $site: String
    $siteType: String
    $suborg: String
  ) {
    getSeriousIncidentsByOrg(
      country: $country
      metricDate: $metricDate
      metricDateStart: $metricDateStart
      metricDuration: $metricDuration
      org: $org
      site: $site
      siteType: $siteType
      suborg: $suborg
    ) {
      metricCount
      metricCountry
      metricDimension
      metricOrg
      metricRegion
      metricSite
      metricSubOrg
      metricType
      metricWeek
    }
  }
`;
export const getSeriousIncidentsBySubOrg = /* GraphQL */ `
  query GetSeriousIncidentsBySubOrg(
    $country: String
    $metricDate: String!
    $metricDateStart: String
    $metricDuration: String!
    $org: String
    $site: String
    $siteType: String
    $suborg: String
  ) {
    getSeriousIncidentsBySubOrg(
      country: $country
      metricDate: $metricDate
      metricDateStart: $metricDateStart
      metricDuration: $metricDuration
      org: $org
      site: $site
      siteType: $siteType
      suborg: $suborg
    ) {
      metricCount
      metricCountry
      metricDimension
      metricOrg
      metricRegion
      metricSite
      metricSubOrg
      metricType
      metricWeek
    }
  }
`;
export const getSeriousIncidentsOutcomeTrends = /* GraphQL */ `
  query GetSeriousIncidentsOutcomeTrends(
    $country: String
    $metricDate: String!
    $metricDateStart: String
    $metricDuration: String!
    $org: String
    $site: String
    $siteType: String
    $suborg: String
  ) {
    getSeriousIncidentsOutcomeTrends(
      country: $country
      metricDate: $metricDate
      metricDateStart: $metricDateStart
      metricDuration: $metricDuration
      org: $org
      site: $site
      siteType: $siteType
      suborg: $suborg
    ) {
      metricCount
      metricCountry
      metricDimension
      metricOrg
      metricRegion
      metricSite
      metricSubOrg
      metricType
      metricWeek
    }
  }
`;
export const getSeriousIncidentsPerformingOrgs = /* GraphQL */ `
  query GetSeriousIncidentsPerformingOrgs(
    $country: String
    $metricDate: String!
    $metricDateStart: String
    $metricDuration: String!
    $org: String
    $site: String
    $siteType: String
    $suborg: String
  ) {
    getSeriousIncidentsPerformingOrgs(
      country: $country
      metricDate: $metricDate
      metricDateStart: $metricDateStart
      metricDuration: $metricDuration
      org: $org
      site: $site
      siteType: $siteType
      suborg: $suborg
    ) {
      metricCount
      metricCountry
      metricDimension
      metricOrg
      metricRegion
      metricSite
      metricSubOrg
      metricType
      metricWeek
    }
  }
`;
export const getSeriousIncidentsPerformingSites = /* GraphQL */ `
  query GetSeriousIncidentsPerformingSites(
    $country: String
    $metricDate: String!
    $metricDateStart: String
    $metricDuration: String!
    $org: String
    $site: String
    $siteType: String
    $suborg: String
  ) {
    getSeriousIncidentsPerformingSites(
      country: $country
      metricDate: $metricDate
      metricDateStart: $metricDateStart
      metricDuration: $metricDuration
      org: $org
      site: $site
      siteType: $siteType
      suborg: $suborg
    ) {
      metricCount
      metricCountry
      metricDimension
      metricOrg
      metricRegion
      metricSite
      metricSubOrg
      metricType
      metricWeek
    }
  }
`;
export const getSeriousIncidentsRequiringReview = /* GraphQL */ `
  query GetSeriousIncidentsRequiringReview(
    $country: String
    $metricDate: String!
    $metricDateStart: String
    $metricDuration: String!
    $org: String
    $site: String
    $siteType: String
    $suborg: String
  ) {
    getSeriousIncidentsRequiringReview(
      country: $country
      metricDate: $metricDate
      metricDateStart: $metricDateStart
      metricDuration: $metricDuration
      org: $org
      site: $site
      siteType: $siteType
      suborg: $suborg
    ) {
      metricCount
      metricCountry
      metricDimension
      metricOrg
      metricRegion
      metricSite
      metricSubOrg
      metricType
      metricWeek
    }
  }
`;
export const getTotalCases = /* GraphQL */ `
  query GetTotalCases(
    $metricDate: String!
    $metricDateStart: String
    $metricDuration: String!
    $org: String
    $region: String
    $site: String
    $siteType: String
    $subOrg: String
    $subRegion: String
    $superRegion: String
  ) {
    getTotalCases(
      metricDate: $metricDate
      metricDateStart: $metricDateStart
      metricDuration: $metricDuration
      org: $org
      region: $region
      site: $site
      siteType: $siteType
      subOrg: $subOrg
      subRegion: $subRegion
      superRegion: $superRegion
    ) {
      metricCount
      metricCountry
      metricDimension
      metricOrg
      metricRegion
      metricSite
      metricSubOrg
      metricType
      metricWeek
    }
  }
`;
export const getTotalCasesWithGenAI = /* GraphQL */ `
  query GetTotalCasesWithGenAI(
    $metricDate: String!
    $metricDateStart: String
    $metricDuration: String!
    $org: String
    $region: String
    $site: String
    $siteType: String
    $subOrg: String
    $subRegion: String
    $superRegion: String
  ) {
    getTotalCasesWithGenAI(
      metricDate: $metricDate
      metricDateStart: $metricDateStart
      metricDuration: $metricDuration
      org: $org
      region: $region
      site: $site
      siteType: $siteType
      subOrg: $subOrg
      subRegion: $subRegion
      superRegion: $superRegion
    ) {
      metricCount
      metricCountry
      metricDimension
      metricOrg
      metricRegion
      metricSite
      metricSubOrg
      metricType
      metricWeek
    }
  }
`;
export const getTotalClaims = /* GraphQL */ `
  query GetTotalClaims(
    $metricDate: String!
    $metricDateStart: String
    $metricDuration: String!
    $org: String
    $region: String
    $site: String
    $siteType: String
    $subOrg: String
    $subRegion: String
    $superRegion: String
  ) {
    getTotalClaims(
      metricDate: $metricDate
      metricDateStart: $metricDateStart
      metricDuration: $metricDuration
      org: $org
      region: $region
      site: $site
      siteType: $siteType
      subOrg: $subOrg
      subRegion: $subRegion
      superRegion: $superRegion
    ) {
      metricCount
      metricCountry
      metricDimension
      metricOrg
      metricRegion
      metricSite
      metricSubOrg
      metricType
      metricWeek
    }
  }
`;
export const getTotalOutcomes = /* GraphQL */ `
  query GetTotalOutcomes(
    $metricDate: String!
    $metricDateStart: String
    $metricDuration: String!
    $org: String
    $region: String
    $site: String
    $siteType: String
    $subOrg: String
    $subRegion: String
    $superRegion: String
  ) {
    getTotalOutcomes(
      metricDate: $metricDate
      metricDateStart: $metricDateStart
      metricDuration: $metricDuration
      org: $org
      region: $region
      site: $site
      siteType: $siteType
      subOrg: $subOrg
      subRegion: $subRegion
      superRegion: $superRegion
    ) {
      metricCount
      metricCountry
      metricDimension
      metricOrg
      metricRegion
      metricSite
      metricSubOrg
      metricType
      metricWeek
    }
  }
`;
export const getTotalRecordkeepingCases = /* GraphQL */ `
  query GetTotalRecordkeepingCases(
    $metricDate: String!
    $metricDateStart: String
    $metricDuration: String!
    $org: String
    $site: String
    $siteType: String
    $suborg: String
  ) {
    getTotalRecordkeepingCases(
      metricDate: $metricDate
      metricDateStart: $metricDateStart
      metricDuration: $metricDuration
      org: $org
      site: $site
      siteType: $siteType
      suborg: $suborg
    ) {
      metricCount
      metricCountry
      metricDimension
      metricOrg
      metricRegion
      metricSite
      metricSubOrg
      metricType
      metricWeek
    }
  }
`;
export const getTotalSeriousIncidents = /* GraphQL */ `
  query GetTotalSeriousIncidents(
    $country: String
    $metricDate: String!
    $metricDateStart: String
    $metricDuration: String!
    $org: String
    $site: String
    $siteType: String
    $suborg: String
  ) {
    getTotalSeriousIncidents(
      country: $country
      metricDate: $metricDate
      metricDateStart: $metricDateStart
      metricDuration: $metricDuration
      org: $org
      site: $site
      siteType: $siteType
      suborg: $suborg
    ) {
      metricCount
      metricCountry
      metricDimension
      metricOrg
      metricRegion
      metricSite
      metricSubOrg
      metricType
      metricWeek
    }
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers(
    $alias: String
    $nextToken: String
    $role: String
    $status: String
  ) {
    getUsers(
      alias: $alias
      nextToken: $nextToken
      role: $role
      status: $status
    ) {
      active
      nextToken
      permissionSet
      pk
      role
      sk
    }
  }
`;
