import React, { lazy, Suspense } from "react";
import { SIDetailsPageProps } from "./SIDetailsPage";

const LazySIDetailsPage = lazy(() => import("./SIDetailsPage"));

/**
 * Lazy component for SIDetailsPage.
 * @param {SIDetailsPageProps} [props] the props
 * @returns {JSX.Element} the lazy component
 */
const SIDetailsPage: React.FC<SIDetailsPageProps> = (props) => (
  <Suspense fallback={null}>
    <LazySIDetailsPage {...props} />
  </Suspense>
);

export default SIDetailsPage;
