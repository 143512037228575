import React, { lazy, Suspense } from "react";
import { ClaimsViewPageProps } from "./ClaimsViewPage";

const LazyClaimsViewPage = lazy(() => import("./ClaimsViewPage"));

/**
 * Lazy component for ClaimsViewPage.
 * @param {ClaimsViewPageProps} [props] the props
 * @returns {JSX.Element} the lazy component
 */
const ClaimsViewPage: React.FC<ClaimsViewPageProps> = (props) => (
  <Suspense fallback={null}>
    <LazyClaimsViewPage {...props} />
  </Suspense>
);

export default ClaimsViewPage;
