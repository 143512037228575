import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ROUTE_PATHS } from "../../App";
import { useRoute } from "../../util/CommonUtils/CommonUtils";
import Badge from "@amzn/meridian/badge";
import SideMenu, { SideMenuLink } from "@amzn/meridian/side-menu";
import { useLocation } from "react-router-dom";
import AppContext from "../../AppContext";
import Tooltip from "@amzn/meridian/tooltip";
import Text from "@amzn/meridian/text";
import {
  checkAdminRole,
  isProd,
} from "../../util/PermissionUtils/PermissionUtils";

export interface SidebarProps {
  open: boolean;
}

interface MenuItemType {
  id: string;
  label: string;
  route: ROUTE_PATHS;
  search?: string;
  disabled?: boolean;
  badge?: number;
  tooltip?: string;
}

type TopMenuItemType = {
  id: string;
  label: string;
  routes: MenuItemType[];
  disabled?: boolean;
};

/**
 * Side component of the Rosie application.
 * @param {SidebarProps} props the props
 * @returns {JSX.Element} the component
 */
const Sidebar: React.FC<SidebarProps> = ({ open }) => {
  const { stage } = useContext(AppContext);
  const { pathname } = useLocation();
  const [hash, setHash] = useState("");
  const [openLinkHash, setOpenLinkHash] = useState<string | null>(null);
  const navigateTo = useRoute();
  const { role, permissions } = useContext(AppContext);

  const menuItems: TopMenuItemType[] = useMemo(
    () => [
      {
        id: "CM",
        label: "Case Management",
        disabled: Object.keys(permissions.CM).every(
          (key) => !permissions.CM[key].view
        ),
        routes: [
          // {
          //   id: "central-dashboard",
          //   label: "Central Record Keeping",
          //   route: ROUTE_PATHS.CasesCentralDashboard,
          //   disabled: !permissions.CM.CentralDashboard.view,
          // },
          {
            id: "dashboard",
            label: "Dashboard",
            route: ROUTE_PATHS.CasesSiteDashboard,
            disabled: !permissions.CM.SiteDashboard.view,
          },
          {
            id: "cases",
            label: "Cases",
            route: ROUTE_PATHS.CasesView,
            disabled: !permissions.CM.Insights.view,
          },
        ],
      },
      {
        id: "WC",
        label: "Workers' Compensation Claims",
        disabled: Object.keys(permissions.WC).every(
          (key) => !permissions.WC[key].view
        ),
        routes: [
          {
            id: "dashboard",
            label: "Dashboard",
            route: ROUTE_PATHS.ClaimsDashboard,
            disabled: !permissions.WC.Dashboard.view,
          },
          {
            id: "claims",
            label: "Claims",
            route: ROUTE_PATHS.ClaimsView,
            disabled: !permissions.WC.Insights.view,
          },
        ],
      },
      {
        id: "SI",
        label: "Serious Incidents",
        disabled: isProd(stage) || Object.keys(permissions.SI).every(
          (key) => !permissions.SI[key].view
        ),
        routes: [
          {
            id: "dashboard",
            label: "Dashboard",
            route: ROUTE_PATHS.SIsDashboard,
            disabled: !permissions.SI.Dashboard.view,
          },
          {
            id: "serious-incidents",
            label: "Serious Incidents",
            route: ROUTE_PATHS.SIsView,
            disabled: !permissions.SI.Insights.view,
          },
          {
            id: "projects",
            label: "Projects",
            route: ROUTE_PATHS.SIsProjects,
            disabled: !permissions.SI.Projects.view,
          },
        ],
      },
      {
        id: "permissions",
        label: "Permissions",
        disabled: !checkAdminRole(role),
        routes: [
          {
            id: "users",
            label: "Users",
            route: ROUTE_PATHS.Users,
          },
          {
            id: "roles",
            label: "Roles",
            route: ROUTE_PATHS.Roles,
          },
        ],
      },
    ],
    [role, permissions, stage]
  );

  /**
   * Function to get default open link hash from hash.
   * @param {string[]} [parts] the parts of the path.
   * @returns {string} the open link hash to set.
   */
  const fetchOpenLinkHash = useCallback(
    (newHash: string): string =>
      menuItems.find(({ id, routes }) =>
        routes.find((r) => `${id}-${r.id}` === newHash)
      )?.id ?? "",
    [menuItems]
  );

  /**
   * Function to handle clicking on a menu item to a page.
   * @param {ROUTE_PATHS} [route] the route
   */
  const handleClick =
    (route: ROUTE_PATHS, search?: string) => (newHash: string) => {
      if (hash !== newHash) {
        setHash(newHash);
        navigateTo(route, {}, search)();
      }
    };

  /**
   * Function to toggle opening one of the sidebar menu links.
   * @param {string} [id] the id of the side menu link
   */
  const toggleOpen = (id: string) => (newHash: string) => {
    setHash(newHash);
    setOpenLinkHash(openLinkHash === id ? null : newHash);
  };

  // Set hash upon loading application.
  useEffect(() => {
    // Search for the route used.
    // Note: for details pages, strip out the additional information.
    const hash: string =
      menuItems
        .flatMap(({ id, routes }) =>
          routes.map((r) => ({ id: `${id}-${r.id}`, route: r.route }))
        )
        .find(({ route }) => route === `/${pathname.split("/")[1]}`)?.id ?? "";
    setHash(hash);
    setOpenLinkHash(fetchOpenLinkHash(hash));
  }, [menuItems, fetchOpenLinkHash, pathname]);

  return (
    <SideMenu open={open}>
      {menuItems
        .filter(({ disabled, routes }) => !disabled && routes.length)
        .map(({ id, label, routes }) => (
          <SideMenuLink
            key={id}
            href={id}
            selected={hash === id}
            open={openLinkHash === id}
            onClick={toggleOpen(id)}
          >
            {label}
            {routes
              .filter((r) => !r.disabled)
              .map((r) => {
                const subHash = `${id}-${r.id}`;
                const text = (
                  <Text>
                    {r.label}
                    {r.badge !== undefined && <Badge value={r.badge} />}
                  </Text>
                );
                return (
                  <SideMenuLink
                    key={subHash}
                    href={subHash}
                    selected={hash === subHash}
                    onClick={handleClick(r.route, r.search)}
                  >
                    {r.tooltip ? (
                      <Tooltip position="end" title={r.tooltip}>
                        {text}
                      </Tooltip>
                    ) : (
                      text
                    )}
                  </SideMenuLink>
                );
              })}
          </SideMenuLink>
        ))}
    </SideMenu>
  );
};

export default Sidebar;
