import React, { lazy, Suspense } from "react";
import { CaseDetailsPageProps } from "./CaseDetailsPage";

const LazyCaseDetailsPage = lazy(() => import("./CaseDetailsPage"));

/**
 * Lazy component for CaseDetailsPage.
 * @param {CaseDetailsPageProps} [props] the props
 * @returns {JSX.Element} the lazy component
 */
const CaseDetailsPage: React.FC<CaseDetailsPageProps> = (props) => (
  <Suspense fallback={null}>
    <LazyCaseDetailsPage {...props} />
  </Suspense>
);

export default CaseDetailsPage;
