import React, { lazy, Suspense } from "react";
import { SIsViewPageProps } from "./SIsViewPage";

const LazySIsViewPage = lazy(() => import("./SIsViewPage"));

/**
 * Lazy component for SIsViewPage.
 * @param {SIsViewPageProps} [props] the props
 * @returns {JSX.Element} the lazy component
 */
const SIsViewPage: React.FC<SIsViewPageProps> = (props) => (
  <Suspense fallback={null}>
    <LazySIsViewPage {...props} />
  </Suspense>
);

export default SIsViewPage;
