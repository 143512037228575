import React, { lazy, Suspense } from "react";
import { RolesViewPageProps } from "./RolesViewPage";

const LazyRolesViewPage = lazy(() => import("./RolesViewPage"));

/**
 * Lazy component for RolesViewPage.
 * @param {RolesViewPageProps} [props] the props
 * @returns {JSX.Element} the lazy component
 */
const RolesViewPage: React.FC<RolesViewPageProps> = (props) => (
  <Suspense fallback={null}>
    <LazyRolesViewPage {...props} />
  </Suspense>
);

export default RolesViewPage;
