import React, { lazy, Suspense } from "react";
import { CasesSiteDashboardPageProps } from "./CasesSiteDashboardPage";

const LazyCasesSiteDashboardPage = lazy(() => import("./CasesSiteDashboardPage"));

/**
 * Lazy component for CasesSiteDashboardPage.
 * @param {CasesSiteDashboardPageProps} [props] the props
 * @returns {JSX.Element} the lazy component
 */
const CasesSiteDashboardPage: React.FC<CasesSiteDashboardPageProps> = (props) => (
  <Suspense fallback={null}>
    <LazyCasesSiteDashboardPage {...props} />
  </Suspense>
);

export default CasesSiteDashboardPage;
