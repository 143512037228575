import React, { lazy, Suspense } from "react";
import { UsersViewPageProps } from "./UsersViewPage";

const LazyUsersViewPage = lazy(() => import("./UsersViewPage"));

/**
 * Lazy component for UsersViewPage.
 * @param {UsersViewPageProps} [props] the props
 * @returns {JSX.Element} the lazy component
 */
const UsersViewPage: React.FC<UsersViewPageProps> = (props) => (
  <Suspense fallback={null}>
    <LazyUsersViewPage {...props} />
  </Suspense>
);

export default UsersViewPage;
