import {
  AppStage,
  DropdownOptionType,
  UserGrants,
  UserPermission,
} from "../../types/CommonTypes";
import { toProperCase } from "../CommonUtils/CommonUtils";
import {
  RoleScope,
  SCOPE_VALUES,
  OrgScope,
  FeatureFlag,
  FEATURE_FLAGS,
  HierarchyKey,
  ORGS,
} from "../../types/PermissionTypes";
import { Role } from "../../API";
import { createOptions } from "../InputUtils/InputUtils";

/**
 * Function to convert a string array to a record for User Permissions.
 * @param {string[]} [values] the values as a string array
 * @returns {Record<UserPermission, boolean>} the record for UserPermissions
 */
export const convertPermsToRecord = (
  values: string[]
): Record<UserPermission, boolean> =>
  values.reduce(
    (result, value) => ({ ...result, [value.toLocaleLowerCase()]: true }),
    {
      export: false,
      view: false,
      manage: false,
    }
  );

/**
 * Function to convert a record for User Permissions to a string array.
 * @param {Record<UserPermission, boolean>} [values] the record for UserPermissions
 * @returns {string[]} the string array
 */
export const convertPermsToStringArray = (
  values: Record<UserPermission, boolean>
): string[] =>
  Object.entries(values)
    .filter(([_, value]) => value)
    .map(([key]) => toProperCase(key));

/**
 * Function to return the scope values given the top value.
 * @param {string} [topValue] the highest value
 * @returns {string[]} the values to set the scope
 */
export const determineScopeValues = (
  org: string,
  topValue: HierarchyKey
): HierarchyKey[] =>
  SCOPE_VALUES[org] && SCOPE_VALUES[org].includes(topValue)
    ? SCOPE_VALUES[org].slice(SCOPE_VALUES[org].indexOf(topValue))
    : [];

/**
 * Function to determine if the user can view the given grant on Users
 * @param {string[] | undefined} [grants] the array from the grants of a user
 * @param {string[] | undefined} [filters] the array from the filter
 * @returns {boolean} if the user is allowed
 */
export const handleUserGrantCheck = (
  grants: string[] | undefined,
  filters: string[]
): boolean => {
  if (!grants?.length || !filters.length) {
    return true;
  }

  // Convert string to array.
  return filters.every((v) => grants?.includes(v));
};

/**
 * Function to return the top value of the scope.
 * @param {string} [org] the org
 * @param {OrgScope} [scope] the scope object
 * @returns {string} the top value of the scope
 */
export const getTopValueOfScope = (
  org: string,
  scope: OrgScope
): HierarchyKey => {
  // Count how many "trues" there are.
  const trues: number = Object.values(scope).reduce(
    (sum, value) => sum + Number(value),
    0
  );
  const scopeValues: HierarchyKey[] = SCOPE_VALUES[org] ?? [];

  // If 0, the scope is undefined currently; assume the top value of the scope.
  return trues > 0 ? scopeValues[trues - 1] : scopeValues[0];
};

/**
 * Function to return the visual array of a scope.
 * Note: the scope object indicates what would need to be specified for the scope
 * for a role, NOT what the role can actually view in data. In the UI, the scope
 * is translated in this way for the user to understand what the role can view.
 * @param {string} [org] the org
 * @param {AMZLScope | NonAMZLScope} [scope] the scope object
 * @returns {string[]} the visual array representing what the user can see
 */
export const convertScopeToVisualArray = (
  org: string = "AMZL",
  scope: OrgScope
): string[] => {
  const topValue = getTopValueOfScope(org, scope);
  return determineScopeValues(org, topValue);
};

/**
 * Function to return the actual scope from the visual array.
 * @param {string} [org] the org
 * @param {string[]} [visualArray] the visual array
 * @returns {OrgScope} the scope object
 */
export const convertVisualArrayToScope = (
  org: string = "AMZL",
  visualArray: string[]
): Record<string, boolean> => {
  const scopeValues: string[] = SCOPE_VALUES[org];
  return scopeValues.reduce(
    (result, key, index) => ({
      ...result,
      [key]: index <= scopeValues.indexOf(visualArray[0]),
    }),
    {}
  );
};

/**
 * Function to check if validation is needed for a hierarchy dropdown.
 * @param {keyof AMZLScope | NonAMZLScope} [key] the grant/key of the scope to check.
 * @param {string} [org] the org provided
 * @param {Role | undefined} [role] the role provided
 * @returns {boolean} whether the key needs validation
 */
export const needsValidation = (
  key: HierarchyKey,
  org: string,
  role: Role | undefined
): boolean => {
  if (role) {
    const { sk: roleName, organizationLevel } = role;
    const { scopes }: RoleScope = organizationLevel
      ? JSON.parse(organizationLevel)
      : ({} as RoleScope);

    // Add role exceptions.
    if (roleName === "Regional WHS Manager") {
      if (key === "region") {
        return false; // Makes region optional.
      }
    }

    return scopes?.[org] && scopes[org][key];
  }
  return false;
};

/**
 * Function to generate a validate function with a given role.
 * @param {keyof AMZLScope | NonAMZLScope} [key] the grant/key of the scope to check.
 * @param {string} [org] the org provided
 * @param {Role | undefined} [role] the role provided
 * @returns {Function} the validate function to use
 */
export const handleValidateWithRole =
  (key: HierarchyKey, org: string, role: Role | undefined) =>
  (values: string[]) => {
    const needToValidate = needsValidation(key, org, role);
    return !needToValidate || !!values.length;
  };

/**
 * Function to translate 'AMZN' from grants to 'AMZL' for UI.
 * @param {string} [org] the orgsiness unit / org
 * @returns {string} 'AMZL' if 'AMZN', the same org otherwise
 */
export const handleAMZNCheckForUI = (org: string) =>
  org === "AMZN" ? "AMZL" : org;

/**
 * Function to translate 'AMZL' from UI to 'AMZN' for grants.
 * @param {string} [org] the orgsiness unit / org
 * @returns {string} 'AMZL' if 'AMZN', the same org otherwise
 */
export const handleAMZLCheckForGrants = (org: string) =>
  org === "AMZL" ? "AMZN" : org;

/**
 * Function to return whether the feature should be enabled for users.
 * @param {string} [alias] the alias of the user
 * @param {FeatureFlag} [flag] the flag to limit for the UI
 * @returns {boolean} if the user is allowed to see
 */
export const checkFeatureEnabled = (
  email: string,
  flag: FeatureFlag
): boolean => FEATURE_FLAGS[flag].includes(email.split("@")[0]);

/**
 * Function to return whether the user is an admin.
 * @param {string} [role] the role of the user
 * @returns {boolean} if the user should see admin stuff
 */
export const checkAdminRole = (role: string): boolean =>
  role === "Admin" || role === "System Admin";

/**
 * Function to return whether the user is multi-org via grants.
 * @param {UserGrants} [grants] the grants of the user
 * @returns {boolean} if the user is multi-org
 */
export const checkMultiOrg = ({ businessUnit }: UserGrants): boolean =>
  businessUnit.length > 1;

/**
 * Function to sort roles such that System Admin and Admin are first.
 * @param {Role} [a] the left side role
 * @param {Role} [b] the right side role param1
 * @returns {number} either -1 for [a, b], 1 for [a, b], or 0 for equality
 */
export const sortRolesFn = (
  { sk: aRoleName }: Role,
  { sk: bRoleName }: Role
): number => {
  switch (aRoleName) {
    case "System Admin":
      return -1;
    case "Admin":
      if (bRoleName === "System Admin") {
        return 1;
      } else {
        return -1;
      }
    default:
      if (bRoleName === "Admin" || bRoleName === "System Admin") {
        return 1;
      }
      return aRoleName.localeCompare(bRoleName);
  }
};

/**
 * Filter function to hide out of scope orgs.
 * Note: may not be needed after all users are updated.
 * @param {string} [org] the org to check
 * @returns {boolean} whether this org should be shown or hidden
 */
export const hideOrgsFn = (org: string) => org === 'All' || ORGS.includes(handleAMZNCheckForUI(org));

export const ORG_OPTIONS_WITH_ALL: DropdownOptionType[] = createOptions({
  data: ORGS,
});
export const ORG_OPTIONS: DropdownOptionType[] = createOptions({
  data: ORGS,
  omitAll: true,
});

export const STATUS_OPTIONS: DropdownOptionType[] = createOptions({
  data: ["Active", "Disabled"],
  omitAll: true,
});

/**
 * Function to check if the app stage is prod.
 * @param {AppStage} [stage] the stage of the app
 * @returns {boolean} if the stage is prod
 */
export const isProd = (stage: AppStage): boolean => stage === 'prod';