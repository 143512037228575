import React from "react";
import {
  UserGrants,
  UserPermissions,
  UserAttributes,
  AppStage,
} from "./types/CommonTypes";

const { REACT_APP_STAGE } = process.env;

/**
 * Information to include throughout the application.
 */
export interface AppContextValue {
  email: string;
  name: string;
  role: string;
  grants: UserGrants;
  permissions: UserPermissions;
  stage: AppStage;
  addToast: (content: string) => void;
  isDarkMode: boolean;
  sidebarOpen: boolean;
  canSeeGenAIInsights: boolean | undefined;
}

export interface AppContextProps {
  children: JSX.Element;
  attributes: UserAttributes;
  addToast: (content: string) => void;
  isDarkMode: boolean;
  sidebarOpen: boolean;
  canSeeGenAIInsights: boolean | undefined;
}

/**
 * App Context provider.
 */
export const AppContextProvider = ({
  children,
  attributes,
  addToast,
  isDarkMode,
  sidebarOpen,
  canSeeGenAIInsights,
}: AppContextProps) => {
  const {
    email,
    given_name: firstName,
    family_name: lastName,
    "custom:role": role,
  } = attributes;
  const grants: UserGrants = attributes["custom:grants"].length
    ? JSON.parse(attributes["custom:grants"])
    : {};
  const permissions: UserPermissions = attributes["custom:permissions"].length
    ? JSON.parse(attributes["custom:permissions"])
    : {};

  const value: AppContextValue = {
    email,
    name: `${firstName} ${lastName}`,
    role,
    grants,
    permissions,
    addToast,
    isDarkMode,
    stage: REACT_APP_STAGE as AppStage,
    sidebarOpen,
    canSeeGenAIInsights,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

const AppContext = React.createContext({} as AppContextValue);
export default AppContext;
