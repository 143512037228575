import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import Menu, { MenuItem } from "@amzn/meridian/menu";
import Masthead, {
  MastheadTitle,
  MastheadMenuButton,
} from "@amzn/meridian/masthead";
import React, { useContext, useRef, useState } from "react";
import { ROUTE_PATHS } from "../../App";
import AppContext from "../../AppContext";
import { useRoute } from "../../util/CommonUtils/CommonUtils";
import Theme from "@amzn/meridian/theme";
import HeaderTokens from "./HeaderTokens";
import logoProd from "../../assets/images/logo/Project-Rosie_Prod_White.png";
import logoBeta from "../../assets/images/logo/Project-Rosie_Beta_White.png";
import Row from "@amzn/meridian/row";
import Heading from "@amzn/meridian/heading";
import {
  checkFeatureEnabled,
  isProd,
} from "../../util/PermissionUtils/PermissionUtils";
import placeholderImage from "../../assets/images/placeholder.png";
import bulbTokens from "@amzn/meridian-tokens/base/icon/bulb";
import helpTokens from "@amzn/meridian-tokens/base/icon/help";
import styles from "./Header.module.scss";
import Tooltip from "@amzn/meridian/tooltip";
import { AppStage } from "../../types/CommonTypes";

export interface HeaderProps {
  toggleDarkMode: () => void;
  toggleSidebar?: () => void;
}

const ROSIE_WIKI_URL = "https://w.amazon.com/bin/view/Rosie/";

/**
 * Top component of the Rosie application.
 * @param {HeaderProps} props the props
 * @returns {JSX.Element} the component
 */
const Header: React.FC<HeaderProps> = ({ toggleSidebar, toggleDarkMode }) => {
  const { isDarkMode, canSeeGenAIInsights, email, stage } =
    useContext(AppContext);
  const navigateTo = useRoute();

  // Log out menu.
  const [showHeaderMenu, setShowHeaderMenu] = useState(false);
  const menuButtonRef = useRef(undefined);

  /**
   * Function to return a handler for the header menu.
   * @param {boolean} [value] true to show, false to close
   * @returns {*}
   */
  const toggleHeaderMenu = (value: boolean) => () => setShowHeaderMenu(value);

  /**
   * Function to clear local storage and reload.
   * @returns {*}
   */
  const handleRefresh = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.reload();
  };

  /**
   * Function to handle the error for an image.
   * @param e
   */
  const handleImageError = (e) => {
    e.target.src = placeholderImage;
  };

  const REQUEST_AN_ENHANCEMENT_URL =
"https://issues.amazon.com/issues/create?assignedFolder=9be7e367-373a-437e-9cc8-89d26994f087";

  const REQUEST_SUPPORT_URL: Record<AppStage, string> = {
    beta: "https://issues.amazon.com/issues/create?template=d3858bf1-845c-47a9-86ea-c1cc8758a2c4",
    dev: "https://issues.amazon.com/issues/create?template=d3858bf1-845c-47a9-86ea-c1cc8758a2c4",
    // Prod link different.
    prod: "https://sim.amazon.com/issues/create?assignedFolder=fc80bd5f-c993-48b2-b673-7c4f36e142cf",
  };

  const alias = email.split("@")[0];
  const AMAZON_BADGE_IMAGE = `https://internal-cdn.amazon.com/badgephotos.amazon.com/?Region=master&uid=${alias}`;

  /**
   * Small component for the menu button.
   */
  const MenuButton = () => (
    <Theme mode="dark">
      <MastheadMenuButton onClick={toggleSidebar} />
    </Theme>
  );

  MenuButton.WrappedComponent = MastheadMenuButton;

  /**
   * Small component for the menu button.
   */
  const WikiButton = () => (
    <Theme mode="dark">
      <Tooltip position="bottom" title="Go to Wiki">
        <Button type="icon" href={ROSIE_WIKI_URL} target="_blank">
          <Icon tokens={helpTokens} />
        </Button>
      </Tooltip>
    </Theme>
  );

  WikiButton.WrappedComponent = Button;

  return (
    <Theme tokens={HeaderTokens}>
      <Masthead
        backgroundColor={isDarkMode ? styles.darkMode : styles.lightMode}
        menuButtonComponent={toggleSidebar ? MenuButton : undefined}
      >
        {toggleSidebar && <MenuButton />}
        <MastheadTitle onClick={navigateTo(ROUTE_PATHS.Home)}>
          <img
            src={isProd(stage) ? logoProd : logoBeta}
            alt={"Amazon | Rosie"}
            height={32}
          />
        </MastheadTitle>
        {checkFeatureEnabled(email, "DEV") && (
          <Row alignmentHorizontal="center" spacingInset="none 200">
            <Heading level={6}>
              [DEVS ONLY ({stage})]{" "}
              {canSeeGenAIInsights ? `${alias} can see Gen AI Insights` : ""}
            </Heading>
            <Button
              className={
                isDarkMode
                  ? styles.DarkModeMastheadBtn
                  : styles.LightModeMastheadBtn
              }
              onClick={() => {
                window.open(
                  `http://${
                    window.location.hostname === "localhost"
                      ? "beta.rosie.whs.amazon.dev"
                      : "localhost:3000"
                  }${window.location.pathname}${window.location.search}`
                );
              }}
              target="_blank"
            >
              {`Check ${
                window.location.hostname === "localhost" ? "Beta" : "Local"
              } Environment`}
            </Button>
          </Row>
        )}
        <WikiButton />
        <Button
          ref={menuButtonRef}
          type="link"
          onClick={toggleHeaderMenu(true)}
        >
          <img
            src={AMAZON_BADGE_IMAGE}
            style={{ objectFit: "cover", borderRadius: "50%" }}
            alt={alias}
            height={40}
            width={40}
            onError={handleImageError}
          />
        </Button>
        <Menu
          anchorNode={menuButtonRef.current}
          open={showHeaderMenu}
          position="bottom"
          onClose={toggleHeaderMenu(false)}
        >
          <MenuItem onClick={toggleDarkMode}>
            Toggle {isDarkMode ? "Light" : "Dark"} Mode{" "}
            <Icon tokens={bulbTokens} />
          </MenuItem>
          <MenuItem href={REQUEST_SUPPORT_URL[stage]} target="_blank">
            Request support
          </MenuItem>
          <MenuItem href={REQUEST_AN_ENHANCEMENT_URL} target="_blank">
            Request an enhancement
          </MenuItem>
          <MenuItem onClick={handleRefresh}>Refresh</MenuItem>
        </Menu>
      </Masthead>
    </Theme>
  );
};

export default Header;
