export type AppStage = 'dev' | 'beta' | 'prod';

export interface UserAttributes {
  email: string;
  family_name: string;
  given_name: string;
  "custom:isActive": string;
  "custom:grants": string;
  "custom:permissions": string;
  "custom:role": string;
}

export interface UserGrants {
  businessUnit: string[];
  // Shared
  region?: string[];
  site?: string[];
  // AMZL
  superRegion?: string[];
  subRegion?: string[];
  // Non-AMZL
  subOrg?: string[];
  siteType?: string[];
}

export enum UserPermission {
  EXPORT = "export",
  VIEW = "view",
  MANAGE = "manage",
}

export interface UserPermissions {
  CM: {
    // Cases Management
    CentralDashboard: Record<UserPermission, boolean>;
    SiteDashboard: Record<UserPermission, boolean>;
    Insights: Record<UserPermission, boolean>;
  };
  SI: {
    // Serious Incidents
    Dashboard: Record<UserPermission, boolean>;
    Insights: Record<UserPermission, boolean>;
    Projects: Record<UserPermission, boolean>;
  };
  WC: {
    // Workers' Compensation
    Dashboard: Record<UserPermission, boolean>;
    Insights: Record<UserPermission, boolean>;
  };
}

export type RosieOfferings = keyof UserPermissions;

export type MetricDurationType = "day" | "week" | "month";

export interface MetricProps {
  metricDate: string;
  metricDateStart?: string;
  metricDuration: MetricDurationType;
  org: string | undefined;
  region?: string;
  site?: string;
  subRegion?: string;
  superRegion?: string;
  subOrg?: string;
  siteType?: string;
}

export interface MatchOptionType {
  details: string[];
  confidence: number;
  warning?: string;
}

export const BadgeColors: Record<BadgeType, string> = {
  B: "Blue",
  Y: "Yellow",
  G: "Green",
  U: "Unknown",
};

export const EmployeeTypes: Record<BadgeType, string> = {
  B: "Amazon Blue Badge",
  Y: "Independent Contractor",
  G: "Dependent Contractor",
  U: "Unknown",
};

export type BadgeType = "B" | "Y" | "G" | "U";

export interface AnalysisDetailType {
  confidence: number;
  description?: string;
}

export type AnalysisType = Record<string, AnalysisDetailType>;

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export interface ApplicationError extends Error {
  errorType: string;
}

export interface ErrorType {
  errors: ApplicationError[];
}

export interface LandingInsightData {
  insight: string;
  value: number;
  color?: string;
  definition?: string;
}

export interface DropdownOptionType {
  label: string;
  value: string;
}

export interface DropdownOptionGroupType {
  label: string;
  options: DropdownOptionType[];
}
