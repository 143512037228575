import React, { lazy, Suspense } from "react";
import { LandingPageProps } from "./LandingPage";

const LazyLandingPage = lazy(() => import("./LandingPage"));

/**
 * Lazy component for LandingPage.
 * @param {LandingPageProps} [props] the props
 * @returns {JSX.Element} the lazy component
 */
const LandingPage: React.FC<LandingPageProps> = (props) => (
  <Suspense fallback={null}>
    <LazyLandingPage {...props} />
  </Suspense>
);

export default LandingPage;
