import React, { useContext } from "react";
import Column from "@amzn/meridian/column";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import AppContext from "../../AppContext";
import Link from "@amzn/meridian/link";

export interface UnauthorizedPageProps {}

const ROSIE_PROD_URL = "https://rosie.whs.amazon.dev/";
const SIM_URL =
  "https://issues.amazon.com/issues/create?assignedFolder=6a728bdd-0850-4de5-b874-169d6b7477c3";

/**
 * Page if the user is not authorized.
 * @param {UnauthorizedPageProps} [props] the props
 * @returns {JSX.Element} the component
 */
const UnauthorizedPage: React.FC<UnauthorizedPageProps> = () => {
  const { stage } = useContext(AppContext);

  /**
   * Function to handle the message based on the stage.
   */
  const handleMessage = (): string | JSX.Element => {
    switch (stage) {
      case "beta":
        return (
          <>
            You are not authorized to access Rosie in Beta. Please navigate to{" "}
            <Link href={ROSIE_PROD_URL}>Rosie's Production Environment</Link>.
          </>
        );
      case "prod":
        return (
          <>
            You are not authorized to access Rosie. For permissions, please
            create a{" "}
            <Link href={SIM_URL} target="_blank">
              SIM
            </Link>
            .
          </>
        );
      default:
        return "You are not authorized to access Rosie. Please contact your supervisor to request access to this application.";
    }
  };

  return (
    <Column spacingInset="500">
      <ErrorMessage title="Unauthorized" message={handleMessage()} />
    </Column>
  );
};

export default UnauthorizedPage;
