// If new orgs are added, add here.
export const ORGS: string[] = ["AMZL", "AMXL", "ATS", "GCF", "Amazon Grocery Logistics (AGL)", "Global Retail"];

export interface HierarchySiteType {
  site: string;
  active: boolean;
}

export interface SubHierarchyType {
  [level: string]: HierarchySiteType[] | SubHierarchyType;
}

export interface HierarchyType {
  [org: string]: SubHierarchyType;
}

export interface HierarchyValues {
  // Shared levels
  region: string[];
  site: string[];
  // AMZL levels
  superRegion: string[];
  subRegion: string[];
  // Non-AMZL levels
  subOrg: string[];
  siteType: string[];
}

/**
 * Levels to exclude from the results of the site hierarchy, set up
 * as its own hierarchy.
 * 
 * How this works:
 * - For the level to ignore, set its value to an empty array [].
 * - Parent levels should be an object; this will be traversed through.
 * - During traversal, if it passes an Array.isArray check, code knows to skip.
 */
export const EXCLUDED_HIERARCHY: HierarchyType = {
  AMZL: {
    North: [],
    "North (Canada)": []
  },
  GCF: {
    NACF: {
      "Canada FCs": []
    }
  },
  ATS: {
    NASC: {
      "Sort Center": {
        "West/Canada": {
          "Canada": []
        }
      }
    }
  }
};

export type HierarchyKey = "businessUnit" | "org" | keyof HierarchyValues;

export interface HierarchyDropdownDict {
  [level: string]: JSX.Element;
}

export interface AMZLScope {
  businessUnit: boolean;
  superRegion: boolean;
  region: boolean;
  site: boolean;
}

export interface ATSScope {
  org: boolean;
  subOrg: boolean;
  siteType: boolean;
  region: boolean;
  subRegion: boolean;
  site: boolean;
}

// Note: includes GCF, which is Org, Sub Org, Site Type, Region, and Site
export interface NonAMZLScope {
  org: boolean;
  subOrg: boolean;
  region: boolean;
  siteType: boolean;
  site: boolean;
}

export type OrgScope = AMZLScope | ATSScope | NonAMZLScope;

export type ScopesType = Record<string, OrgScope>;

export interface RoleScope {
  multiOrg: boolean;
  scopes?: ScopesType;
}

export const SCOPE_LABELS = {
  businessUnit: "Business Unit",
  superRegion: "Super Region",
  region: "Region",
  subRegion: "Subregion",
  site: "Site",
  org: "Org",
  subOrg: "Sub Org",
  siteType: "Site Type",
};

export const SCOPE_PARAMS = {
  superRegion: "super-region",
  region: "region",
  subRegion: "subregion",
  site: "site",
  subOrg: "sub-org",
  siteType: "site-type",
};

// Add the new org here with the defined hierarchy.
// For instance, AMXL has Org, Sub Org, Site Type, and Site.
export const SCOPE_VALUES: Record<string, HierarchyKey[]> = ORGS.reduce(
  (result, org) => {
    let value: string[];
    switch (org) {
      case "AMZL":
        value = ["businessUnit", "superRegion", "region",  "site"];
        break;
      case "AMXL":
        value = ["org", "subOrg", "siteType", "site"];
        break;
      case "ATS":
        value = ["org", "subOrg", "siteType", "region", "subRegion", "site"];
        break;
      case "GCF":
      case "Global Retail":
        value = ["org", "subOrg", "siteType", "region", "site"];
        break;
      case "Amazon Grocery Logistics (AGL)":
        value = ["org", "subOrg", "siteType", "site"];
        break;
      default:
        value = ["org", "subOrg", "region", "siteType", "site"];
        break;
    }

    return {
      ...result,
      [org]: value,
    };
  },
  {}
);

// NOTE: these are hardcoded feature flags to make sure only certain users can see changes until enabled for everyone.

export type FeatureFlag =
  | "DEV"
  | "OWNER"
  | "STAKEHOLDER"
  | "BETA_STAKEHOLDER";

const proserve: string[] = [
  "njeanpa",
  "bharven",
  "sddoshi",
  "haasnani",
];
const devs: string[] = [
  ...proserve,
  "wanianan",
  "bhawanip",
  "rongeorg",
  "smmendir",
  "srishtiu",
];
const team: string[] = [...devs, "chonachi", "mitcnile"];
const owners: string[] = ["bharyp", "nbroamz"];
const customers: string[] = [...owners, "kmrityun", "mreni", "shaliamz"];

export const FEATURE_FLAGS: Record<FeatureFlag, string[]> = {
  DEV: devs,
  OWNER: [...team, ...owners],
  STAKEHOLDER: [...team, ...customers],
  BETA_STAKEHOLDER: [...proserve, ...owners]
};
