import React, { lazy, Suspense } from "react";
import { SIsDashboardPageProps } from "./SIsDashboardPage";

const LazySIsDashboardPage = lazy(() => import("./SIsDashboardPage"));

/**
 * Lazy component for SIsDashboardPage.
 * @param {SIsDashboardPageProps} [props] the props
 * @returns {JSX.Element} the lazy component
 */
const SIsDashboardPage: React.FC<SIsDashboardPageProps> = (props) => (
  <Suspense fallback={null}>
    <LazySIsDashboardPage {...props} />
  </Suspense>
);

export default SIsDashboardPage;
