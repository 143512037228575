import React, { useEffect, useState } from "react";
import Column from "@amzn/meridian/column";
import Alert from "@amzn/meridian/alert";

export interface BetaMigrationBannerProps {}

// TODO: add later
// const ROSIE_PROD_URL = "https://rosie.whs.amazon.dev/";

/**
 * Banner to show on the top indicating migration to Prod (only in Beta).
 * @param {BetaMigrationBannerProps} props the props
 * @returns {JSX.Element} the component
 */
const BetaMigrationBanner: React.FC<BetaMigrationBannerProps> = () => {
  // Check from session storage (for the banner).
  const sessionItem = sessionStorage.getItem(`Rosie-Beta-Banner`);
  const sessionInputs = sessionItem
    ? JSON.parse(sessionItem)
    : { bannerOpen: true };
  const [bannerOpen, setBannerOpen] = useState(!!sessionInputs?.bannerOpen);

  useEffect(() => {
    sessionStorage.setItem(
      "Rosie-Beta-Banner",
      JSON.stringify({
        bannerOpen,
      })
    );
  }, [bannerOpen]);

  /**
   * Function to close the alert.
   */
  const onClose = () => {
    setBannerOpen(false);
  };

  return bannerOpen ? (
    <Column spacingInset="500 500 none">
      <Alert onClose={onClose}>
        Since <strong>October 16, 2023</strong>, users have migrated from Beta to Production.
      </Alert>
    </Column>
  ) : (
    <></>
  );
};

export default BetaMigrationBanner;
