import React, { lazy, Suspense } from "react";
import { ClaimsDashboardPageProps } from "./ClaimsDashboardPage";

const LazyClaimsDashboardPage = lazy(() => import("./ClaimsDashboardPage"));

/**
 * Lazy component for ClaimsDashboardPage.
 * @param {ClaimsDashboardPageProps} [props] the props
 * @returns {JSX.Element} the lazy component
 */
const ClaimsDashboardPage: React.FC<ClaimsDashboardPageProps> = (props) => (
  <Suspense fallback={null}>
    <LazyClaimsDashboardPage {...props} />
  </Suspense>
);

export default ClaimsDashboardPage;
