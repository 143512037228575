import { API, Auth, Storage } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import styles from "./App.module.scss";
import { AppContextProvider } from "./AppContext";
import { AppStage, ErrorType, UserAttributes } from "./types/CommonTypes";
import WithAuthenticator from "./components/Authentication/WithAuthenticator";
import AppLayout from "@amzn/meridian/app-layout";
import Header from "./navigation/Header/Header";
import Sidebar from "./navigation/Sidebar/Sidebar";
import LandingPage from "./pages/Landing/LandingPage.lazy";
import ClaimsDashboardPage from "./pages/ClaimsDashboard/ClaimsDashboardPage.lazy";
import CasesSiteDashboardPage from "./pages/CasesSiteDashboard/CasesSiteDashboardPage.lazy";
import ClaimsViewPage from "./pages/ClaimsView/ClaimsViewPage.lazy";
import ClaimDetailsPage from "./pages/ClaimDetails/ClaimDetailsPage.lazy";
import CasesViewPage from "./pages/CasesView/CasesViewPage.lazy";
import CaseDetailsPage from "./pages/CaseDetails/CaseDetailsPage.lazy";
import UsersViewPage from "./pages/UsersView/UsersViewPage.lazy";
import RolesViewPage from "./pages/RolesView/RolesViewPage.lazy";
import SIDetailsPage from "./pages/SIDetails/SIDetailsPage.lazy";
import SIsDashboardPage from "./pages/SIsDashboard/SIsDashboardPage.lazy";
import SIsViewPage from "./pages/SIsView/SIsViewPage.lazy";
import SIsProjectsPage from "./pages/SIsProjects/SIsProjectsPage.lazy";
import CasesCentralDashboardPage from "./pages/CasesCentralDashboard/CasesCentralDashboardPage.lazy";
import Toaster from "@amzn/meridian/toaster";
import Alert from "@amzn/meridian/alert";
import Theme from "@amzn/meridian/theme";
import { isProd } from "./util/PermissionUtils/PermissionUtils";
import UnauthorizedPage from "./pages/Unauthorized/UnauthorizedPage";
import BetaMigrationBanner from "./navigation/BetaMigrationBanner/BetaMigrationBanner";
import { FeatureFlagSettings, GetFeatureFlagSettingsQuery } from "./API";
import { getFeatureFlagSettings } from "./graphql/queries";
import { GraphQLResult } from "@aws-amplify/api";
import { AwsRum, AwsRumConfig } from "aws-rum-web";

// Pull from environment.
const {
  REACT_APP_REDIRECT_URL,
  REACT_APP_COGNITO_CLIENT_ID,
  REACT_APP_COGNITO_ID,
  REACT_APP_COGNITO_IDENTITY_POOL_ID,
  REACT_APP_REGION,
  REACT_APP_COGNITO_DOMAIN,
  REACT_APP_GRAPHQL_ENDPOINT,
  REACT_APP_GRAPHQL_AUTH_TYPE,
  REACT_APP_STAGE,
  REACT_APP_RUM_GUEST_ROLE_ARN,
  REACT_APP_RUM_APPLICATION_ID,
  REACT_APP_RUM_COGNITO_IDENTITY_POOL_ID,
} = process.env;

// Configure Amplify authentication.
Auth.configure({
  identityPoolId: REACT_APP_COGNITO_IDENTITY_POOL_ID,
  identityPoolRegion: REACT_APP_REGION,
  userPoolId: REACT_APP_COGNITO_ID,
  region: REACT_APP_REGION,
  userPoolWebClientId: REACT_APP_COGNITO_CLIENT_ID,
  oauth: {
    domain: REACT_APP_COGNITO_DOMAIN,
    scope: ["email", "openid", "profile"],
    redirectSignIn: REACT_APP_REDIRECT_URL,
    redirectSignOut: REACT_APP_REDIRECT_URL,
    responseType: "code",
  },
});

// Configure GraphQL API.
API.configure({
  aws_appsync_region: REACT_APP_REGION,
  aws_appsync_graphqlEndpoint: REACT_APP_GRAPHQL_ENDPOINT,
  aws_appsync_authenticationType: REACT_APP_GRAPHQL_AUTH_TYPE,
  graphql_headers: async () => {
    const session = await Auth.currentSession();
    if (!session.isValid()) {
      Auth.signOut();
      return "";
    }
    return {
      Authorization: session.getIdToken().getJwtToken(),
    };
  },
});

// Configure Storage.
Storage.configure({
  customPrefix: {
    public: "",
    private: "",
    protected: "",
  },
});

// Configure AWS RUM.
let awsRum: AwsRum | undefined;
try {
  if (REACT_APP_RUM_GUEST_ROLE_ARN && REACT_APP_RUM_APPLICATION_ID) {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      guestRoleArn: REACT_APP_RUM_GUEST_ROLE_ARN,
      identityPoolId:
        REACT_APP_STAGE === "dev"
          ? "us-east-1:4435ecc8-b382-4a7f-9ff3-324098be41e9"
          : REACT_APP_RUM_COGNITO_IDENTITY_POOL_ID,
      endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
      telemetries: [
        "performance",
        "errors",
        ["http", { addXRayTraceIdHeader: true }],
      ],
      allowCookies: true,
      enableXRay: true,
    };

    const APPLICATION_ID: string = REACT_APP_RUM_APPLICATION_ID;
    const APPLICATION_VERSION: string = "1.0.0";
    const APPLICATION_REGION: string = REACT_APP_REGION as string;

    // eslint-disable-next-line
    awsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config
    );

    console.log("Cloudwatch RUM successfully set up.");
  }
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
  console.error(error);
}

/** App-level routes, should be used in Link tags etc. */
export enum ROUTE_PATHS {
  Home = "/",
  IndexHome = "/index.html",
  // Cases Management
  CasesView = "/cases",
  CasesSiteDashboard = "/cases-dashboard",
  CasesCentralDashboard = "/cases-central-dashboard",
  CaseDetails = "/cases/:incidentId",
  // Workers' Compensation Claims
  ClaimsDashboard = "/claims-dashboard",
  ClaimsView = "/claims",
  ClaimDetails = "/claims/:site",
  // Serious Incidents
  SIsView = "/incidents",
  SIsDashboard = "/incidents-dashboard",
  SIsProjects = "/incidents-projects",
  SIDetails = "/incidents/:incidentId",
  // Permissions
  Users = "/permissions-users",
  Roles = "/permissions-roles",
}

/** Standard app-level route configuration */
const routes = [
  { path: ROUTE_PATHS.Home, Component: LandingPage },
  { path: ROUTE_PATHS.IndexHome, Component: LandingPage },
  { path: ROUTE_PATHS.ClaimDetails, Component: ClaimDetailsPage },
  { path: ROUTE_PATHS.ClaimsView, Component: ClaimsViewPage },
  { path: ROUTE_PATHS.CasesView, Component: CasesViewPage },
  { path: ROUTE_PATHS.Users, Component: UsersViewPage },
  { path: ROUTE_PATHS.Roles, Component: RolesViewPage },
  { path: ROUTE_PATHS.CaseDetails, Component: CaseDetailsPage },

  // No need to change
  { path: ROUTE_PATHS.CasesSiteDashboard, Component: CasesSiteDashboardPage },
  { path: ROUTE_PATHS.ClaimsDashboard, Component: ClaimsDashboardPage },

  // Not for P0
  ...(!isProd(REACT_APP_STAGE as AppStage)
    ? [
        {
          path: ROUTE_PATHS.CasesCentralDashboard,
          Component: CasesCentralDashboardPage,
        },
        { path: ROUTE_PATHS.SIDetails, Component: SIDetailsPage },
        { path: ROUTE_PATHS.SIsDashboard, Component: SIsDashboardPage },
        { path: ROUTE_PATHS.SIsProjects, Component: SIsProjectsPage },
        { path: ROUTE_PATHS.SIsView, Component: SIsViewPage },
      ]
    : []),
];

/** Properties for App component. */
interface AppProps {
  user: CognitoUser;
}

/** Toaster variables */
interface ToastType {
  id: string;
  content: string;
}

/** Site-wide variables */
interface SiteWideVariables {
  // seeRevamp: boolean;
  darkMode: boolean;
}

let DUMMYID = 0;
const TOASTER_LIMIT = 3;

/**
 * Root level component for Rosie application.
 * @param {AppProps} [props] the props
 * @returns {JSX.Element} the component
 */
const App: React.FC<AppProps> = ({ user }) => {
  const [toasts, setToasts] = useState<ToastType[]>([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [canSeeGenAIInsights, setCanSeeGenAIInsights] = useState<
    boolean | undefined
  >();

  // Fetch the current user's attributes.
  const attributes = user
    .getSignInUserSession()
    ?.getIdToken()
    .decodePayload() as UserAttributes;

  // See if the user can access Rosie.
  const isActive = (attributes["custom:isActive"] ?? "false") === "true";

  // Check from local storage.
  const localItemKey: string = `Rosie-${attributes.email.split("@")[0]}`;
  const localItem = localStorage.getItem(localItemKey);
  const localInputs: SiteWideVariables = localItem
    ? (JSON.parse(localItem) as SiteWideVariables)
    : {
        darkMode: false,
      };
  const [darkMode, setDarkMode] = useState(!!localInputs?.darkMode);

  useEffect(() => {
    localStorage.setItem(
      localItemKey,
      JSON.stringify({
        darkMode,
      })
    );
  }, [localItemKey, darkMode]);

  /**
   * Function to check how to set the feature flags of the user.
   */
  const fetchFeatureFlags = useCallback(async () => {
    if (attributes.email) {
      try {
        const { data } = await (API.graphql({
          query: getFeatureFlagSettings,
          variables: {},
        }) as Promise<GraphQLResult<GetFeatureFlagSettingsQuery>>);

        if (data?.getFeatureFlagSettings) {
          const settings = data.getFeatureFlagSettings as FeatureFlagSettings[];
          const { additionalData }: FeatureFlagSettings =
            settings.find(({ name }) => name === "GENAI_INSIGHTS_ENABLED") ??
            ({
              additionalData: "",
            } as FeatureFlagSettings);

          if (additionalData) {
            const usersToAllowGenAI: string[] = additionalData.split(",");
            const alias: string = attributes.email.split("@")[0];
            setCanSeeGenAIInsights(usersToAllowGenAI.includes(alias));
          }
        }
      } catch (error) {
        // Do something.
        const { errors } = error as ErrorType;
        console.error(errors);
      }
    }
  }, [attributes.email]);

  useEffect(() => {
    fetchFeatureFlags();
  }, [fetchFeatureFlags]);

  /**
   * Function to add to the toaster when possible.
   */
  const addToast = useCallback(
    (content: string) => {
      const newToasts = toasts.concat({
        id: `${++DUMMYID}`,
        content,
      });
      if (toasts.length >= TOASTER_LIMIT) {
        newToasts.shift();
      }
      setToasts(newToasts);
    },
    [toasts]
  );

  /**
   * Function to close the alert.
   */
  const onCloseToast = (id) => setToasts(toasts.filter((t) => t.id !== id));

  /**
   * Function to open and close the sidebar
   */
  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);
  /**
   * Function to toggle dark mode.
   * TODO: update components for this to work
   */
  const toggleDarkMode = () => setDarkMode(!darkMode);

  return (
    <BrowserRouter>
      <AppContextProvider
        attributes={attributes}
        addToast={addToast}
        isDarkMode={darkMode}
        sidebarOpen={sidebarOpen}
        canSeeGenAIInsights={canSeeGenAIInsights}
      >
        <Theme mode={darkMode ? "dark" : "light"}>
          {isActive ? (
            <AppLayout
              backgroundColor={darkMode ? styles.darkModeBackground : undefined}
              headerComponent={Header}
              sidebarComponent={Sidebar}
            >
              {/** Navigation */}
              <Header
                toggleDarkMode={toggleDarkMode}
                toggleSidebar={toggleSidebar}
              />
              <Sidebar open={sidebarOpen} />
              <Toaster toasts={toasts} onCloseToast={onCloseToast}>
                {({ content, onClose }) => (
                  <Alert toast={true} onClose={onClose}>
                    {content}
                  </Alert>
                )}
              </Toaster>

              {/** Content */}
              <div className={styles.viewPosition}>
                {REACT_APP_STAGE === "beta" && <BetaMigrationBanner />}
                <Switch>
                  {routes.map(({ path, Component }) => {
                    return (
                      <Route
                        key={path}
                        path={path}
                        render={Component}
                        exact={true}
                      />
                    );
                  })}
                  {/** By default, reroute to the landing page. */}
                  <Route path="*" render={() => <Redirect to="/" />} />
                </Switch>
              </div>
            </AppLayout>
          ) : (
            <AppLayout headerComponent={Header}>
              {/** Navigation */}
              <Header toggleDarkMode={toggleDarkMode} />

              {/** Content */}
              <div className={styles.viewPosition}>
                <UnauthorizedPage />
              </div>
            </AppLayout>
          )}
        </Theme>
      </AppContextProvider>
    </BrowserRouter>
  );
};

export default WithAuthenticator(App);
